<template>
  <div>
    <In-Head :title="$t('promotions.index.title')" />

    <sub-header :title="$t('promotions.index.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          v-if="$page.props.permissions.canCreate"
          :href="$page.props.routes.create"
          old-link
          preset="add" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`promotions.index.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'name'">
                {{ slotProps.data?.name }}
                <span
                  v-if="slotProps.data?.trashed"
                  class="text-bo-red">({{ $t('generic.deleted2') }})</span>
              </template>
              <template v-else-if="col === 'link'">
                <div class="inline-flex p-1 bg-white border rounded-lg">
                  <input
                    type="text"
                    class="p-0 px-2 border-0 outline-none w-72"
                    :value="slotProps.data?.link"
                    title="App deep link"
                  >

                  <sm-button
                    icon="clipboard"
                    size="icon"
                    color="primary"
                    @click="copyToClipboard(slotProps.data?.link)" />
                </div>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                class="flex justify-end space-x-1">
                <sm-button
                  v-if="slotProps.data?.permissions.canUpdate"
                  v-tippy="$t('generic.edit')"
                  preset="edit-icon"
                  old-link
                  :href="slotProps.data?.routes.edit" />
                <sm-delete-button
                  v-if="slotProps.data?.permissions.canDelete"
                  :route="slotProps.data?.routes.delete"
                  name="deleteSeasonTicketSubscription"
                />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useToast } from "vue-toastification";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const toast = useToast();

async function copyToClipboard(value) {
  if (! window.isSecureContext) {
    toast.error("A másolás csak HTTPS módban működik.");
    return;
  }

  try {
    await navigator.clipboard.writeText(value);
    toast.success("Link a vágólapra másolva", {
      position: "bottom-center"
    });
  } catch ($e) {
    console.log($e);
  }
}

const tableFields = ref([
  "name", "link", "tickets_count"
]);
</script>
