<template>
  <div>
    <In-Head :title="$t('cafeteria.price_list.title')" />

    <sub-header :title="$t('cafeteria.price_list.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card
      no-padding
      :header-title="$t('cafeteria.price_list.title')">
      <sm-table
        :table-data="$page.props.products"
        table-heading-key="cafeteria.price_list.table"
        :table-columns="simpleTableColumns">
        <template #actions="slotProps">
          <div class="flex items-center justify-end space-x-1">
            <sm-button
              v-if="slotProps.data?.permissions.canUpdate"
              v-tippy="$t('generic.buttons.edit')"
              icon="edit"
              size="icon"
              @click="openProductModal(slotProps.data)" />
          </div>
        </template>
      </sm-table>
    </sm-card>
  </div>
</template>

<script setup>
import { router } from "@inertiajs/vue3";

const simpleTableColumns = ref(["name", "price"]);

onMounted(() => {
  router.reload({ only: ["products"] }, { preserveState: true });
});

window.Livewire.on("inertiaRefresh", () => {
  router.reload({ only: ["products"] }, { preserveState: true });
});

const openProductModal = (product) => {
  window.Livewire.emit("openModal", "cafeteria.edit-stock-price", {
    stockType: product.type, stockId: product.id
  });
};
</script>
