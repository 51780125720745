<template>
  <sm-input-wrapper :computed-id="computedId">
    <template
      v-if="$slots.afterLabel"
      #afterLabel>
      <slot name="afterLabel" />
    </template>
    <template
      v-if="$slots.prepend"
      #prepend>
      <slot name="prepend" />
    </template>
    <template
      v-if="$slots.afterInput"
      #afterInput>
      <slot name="afterInput" />
    </template>
    <template
      v-if="$slots.append"
      #append>
      <slot name="append" />
    </template>
    <template
      v-if="$slots.error"
      #error>
      <slot name="error" />
    </template>
    <textarea
      v-if="type === 'textarea'"
      :id="computedId"
      ref="smInput"
      :name="name"
      class="sm-input-text"
      :class="inputClasses"
      :placeholder="placeholder"
      :disabled="disabled"
      :rows="rows"
      :pattern="pattern"
      :value="value || modelValue"
      @input="updateInput($event)" />
    <input
      v-else
      :id="computedId"
      ref="smInput"
      :type="type"
      :name="name"
      class="sm-input-text"
      :class="inputClasses"
      :placeholder="placeholder"
      :disabled="disabled"
      :min="min"
      :max="max"
      :pattern="pattern"
      :value="value || modelValue"
      @keypress.enter="onEnter"
      @input="updateInput($event)"
      @focus="onFocus"
      @change="onChange"
    >
  </sm-input-wrapper>
</template>

<script setup>
import SmInputWrapper from "@/inertia/components/forms/_layouts/SmInputWrapper.vue";
import uniqueId from "lodash.uniqueid";
import { provide } from "vue";

const props = defineProps({
  name: { type: String, required: false, default: "" },
  id: { type: String, required: false, default: "" },
  label: { type: String, required: false, default: "" },
  placeholder: { type: [String, Boolean], required: false, default: "" },
  value: { type: String, required: false, default: "" },
  modelValue: { type: [String, Number], required: false, default: "" },
  tooltip: { type: String, required: false, default: "" },
  type: { type: String, required: false, default: "text" },
  help: { type: String, required: false, default: "" },
  error: { type: [Boolean, String], required: false, default: false },
  errors: { type: Array, required: false, default: () => [] },
  inline: { type: Boolean, required: false, default: false },
  half: { type: Boolean, required: false, default: false },
  disabled: { type: Boolean, required: false, default: false },
  required: { type: Boolean, required: false, default: false },
  optional: { type: Boolean, required: false, default: false },
  rows: { type: [Number, String], required: false, default: 5 },
  min: { type: [Number, String], required: false, default: null },
  max: { type: [Number, String], required: false, default: null },
  inputClasses: { type: String, required: false, default: "" },
  pattern: { type: String, required: false, default: null },
});

provide("props", props);

const smInput = ref();

const emit = defineEmits(["update:modelValue", "onEnter", "onChange", "onFocus"]);

const computedId = computed(() => {
  if (props.id) {
    return props.id;
  } else {
    return `id_${uniqueId()}`;
  }
});

function updateInput(e) {
  emit("update:modelValue", e.target.value);
}

function onEnter() {
  emit("onEnter");
}

function onFocus() {
  emit("onFocus");
}

function onChange(e) {
  emit("onChange", e);
}
</script>
