<template>
  <div>
    <In-Head :title="$t('reports.sold_tickets.title')" />

    <sub-header
      :title="$t('reports.sold_tickets.title')"
      :export-route="$page.props.routes.export"
    >
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`reports.sold_tickets.columns.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template
                v-if="col === 'customer'">
                <span v-if="typeof(slotProps.data?.customer) === 'string'">{{ slotProps.data?.customer }}</span>
                <sm-avatar
                  v-else
                  :user="slotProps.data?.customer" />
              </template>
              <template
                v-else-if="col === 'sold_at'">
                {{ useDateFormat(slotProps.data?.sold_at, 'long') }}
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => { } },
});

const tableFields = ["customer", "ticket", "price", "sold_at", "channel", "customer_group", "stored_by"];
</script>
