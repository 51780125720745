<template>
  <div>
    <In-Head :title="$t('reports.coach_salaries.show.title', {name: $page.props.coach.name})" />

    <sub-header :title="$t('reports.coach_salaries.show.title', {name: $page.props.coach.name})">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.coachSalaries"
          preset="back"
          icon="arrow-left" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-table
        :table-data="$page.props.coachedAppointments"
        table-heading-key="reports.coach_salaries.show.table"
        :table-columns="tableColumns">
        <template #is_substitute_coach="slotProps">
          <sm-boolean-flag :value="slotProps.data?.is_substitute_coach" />
        </template>
        <template #from="slotProps">
          {{ useDateFormat(slotProps.data.from) }}
        </template>
        <template #to="slotProps">
          {{ useDateFormat(slotProps.data.to) }}
        </template>
      </sm-table>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => { } },
});

const tableColumns = ["members_count", "location", "service", "is_substitute_coach", "from", "to"];
</script>
