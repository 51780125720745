<script>
import AbstractCrudFilterType from "../utility/AbstractCrudFilterType";

export default {
  mixins: [AbstractCrudFilterType],
  data() {
    return {
      defaults: {},
      defaultIcon: "search",
    };
  },
  computed: {
    filterData() {
      return {
        term: this.data.data.term,
      };
    },
    displayableValue() {
      const label = this.data.data.term;
      const operator = window.trans("crud_filter.like");

      return `${operator} "${label}"`;
    }
  },
  render() {
    return this.data.filter.options.featured ? this.createDiv("flex items-center w-auto", [
      this.createDiv("mr-2", [
        this.createLabel(this.$t(this.data.filter.label || this.data.filter.field), {default: true}),
      ]),
      this.createDiv(`sm-input-group !mb-0 ${this.data.filter.options.featured ? "small" : ""}`, [
        this.icon ? this.createDiv("sm-input-group-prepend", [
          this.createIcon(this.icon)
        ]) : null,
        this.createInput([this.data.data, "term", "sm-small"]),
      ])
    ]) : this.renderAbstract([
      this.createDiv("sm-col w-8/12", [
        this.createDiv("sm-input-group", [
          this.icon ? this.createDiv("sm-input-group-prepend", [
            this.createIcon(this.icon)
          ]) : null,
          this.createInput([this.data.data, "term"]),
        ]),
      ]),
    ]);
  },
};
</script>
