<template>
  <div>
    <In-Head :title="$t('reports.in_app_purchase_transactions.title')" />

    <sub-header
      :title="$t('reports.in_app_purchase_transactions.title')"
      :export-route="$page.props.routes.export"
    >
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`reports.in_app_purchase_transactions.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              {{ slotProps.data?.[col] }}
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
    <div id="action-modals" />
  </div>
</template>

<script setup>
defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => { } },
});

const tableFields = ["name", "sold_price", "sold_total_amount", "price", "platform"];
</script>
