<template>
  <div>
    <sm-toggle-button
      v-if="toggleButtonOptions.find(data => data.visible)"
      :title="$t('transactions.show.actions')"
      :loading="form.processing"
      :options="toggleButtonOptions" />
    <sm-confirm-dialog
      v-for="modal in modals"
      :key="modal"
      :group="modal" />
  </div>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import { useConfirm } from "primevue/useconfirm";

const props = defineProps({
  showInvoiceModal: { type: Boolean, required: false, default: null },
  transaction: { type: Object, required: false, default: null },
});

const emits = defineEmits(["triggerShowInvoiceModal"]);

const confirm = useConfirm();
const form = useForm({});

const modals = ref(["refund", "unlock", "download_invoice", "create_invoice", "delete"]);

const afterPost = ref({
  only: ["transaction", "flash", "errors"],
  preserveState: true
});

const refundAction = () => {
  confirm.require({
    group: "refund",
    message: window.trans("transactions.show.refund_modal.title"),
    accept: () => {
      form.post(props.transaction.routes.refund, {...afterPost.value});
    }
  });
};

const unlockAction = () => {
  confirm.require({
    group: "unlock",
    message: window.trans("transactions.show.unlock_modal.title"),
    accept: () => {
      form.post(props.transaction.routes.unlock, {...afterPost.value});
    }
  });
};

const deleteAction = () => {
  confirm.require({
    group: "delete",
    message: window.trans("transactions.show.delete_modal.title"),
    accept: () => {
      form.post(props.transaction.routes.delete, {...afterPost.value});
    }
  });
};

const downloadInvoiceAction = () => {
  confirm.require({
    group: "download_invoice",
    message: window.trans("transactions.show.download_invoice_modal.title"),
    accept: () => {
      nextTick(() => {
        document.getElementById("downloadTransactionButton").click();
      });
    }
  });
};

const createInvoiceAction = () => {
  confirm.require({
    group: "create_invoice",
    message: window.trans("transactions.show.create_invoice_modal.title"),
    accept: () => {
      form.post(props.transaction.routes.download_invoice, {...afterPost.value});
    }
  });
};

const pickInvoiceAction = () => {
  emits("triggerShowInvoiceModal");
};

const toggleButtonOptions = ref([
  {
    id: "refund",
    label: window.trans("transactions.show.refund_modal.button"),
    icon: "skip-back",
    action: refundAction,
    visible: props.transaction.refundable && props.transaction.permissions.canRefund
  },
  {
    id: "unlock",
    label: window.trans("transactions.show.unlock_modal.button"),
    icon: "unlock",
    action: unlockAction,
    visible: props.transaction.unlockable && props.transaction.permissions.canUnlock
  },
  {
    id: "download_invoice",
    label: props.transaction.invoice.transaction_invoice_id,
    icon: "inbox",
    action: downloadInvoiceAction,
    visible: props.transaction.permissions.canInvoice && props.transaction.invoice.transaction_invoice_exists,
    style: !props.transaction.billable ? "pointer-events-none" : ""
  },
  {
    id: "create_invoice",
    label: window.trans("transactions.show.create_invoice_modal.button"),
    icon: "inbox",
    action: createInvoiceAction,
    visible: props.transaction.permissions.canInvoice && !props.transaction.invoice.transaction_invoice_exists && props.transaction.permissions.createInvoice && props.transaction.user_invoice_settings,
    style: !props.transaction.billable ? "pointer-events-none" : ""
  },
  {
    id: "pick_invoice",
    label: window.trans("transactions.show.pick_invoice_modal.button"),
    icon: "inbox",
    action: pickInvoiceAction,
    visible: props.transaction.permissions.canInvoice && !props.transaction.invoice.transaction_invoice_exists && props.transaction.permissions.createInvoice && !props.transaction.user_invoice_settings,
    style: !props.transaction.billable ? "pointer-events-none" : ""
  },
  {
    id: "delete",
    label: window.trans("transactions.show.delete_modal.button"),
    icon: "trash-2",
    action: deleteAction,
    visible: props.transaction.can_be_deleted && props.transaction.permissions.canDelete
  },
]);
</script>
