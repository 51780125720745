<template>
  <div>
    <In-Head :title="$t('reports.sold_products.title')" />

    <sub-header
      :title="$t('reports.sold_products.title')"
      :export-route="$page.props.routes.export"
    >
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card>
      <div class="flex space-x-1">
        <info-card
          v-for="type in aggregates"
          :key="type.name"
          :loading="!$page.props.table?.aggregates"
          :data="type" />
      </div>
    </sm-card>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`reports.sold_products.columns.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template
                v-if="col === 'customer'">
                <span v-if="typeof(slotProps.data?.customer) === 'string'">{{ slotProps.data?.customer }}</span>
                <sm-avatar
                  v-else
                  :user="slotProps.data?.customer" />
              </template>
              <template v-else-if="col === 'sold'">
                {{ useDateFormat(slotProps.data?.sold, 'long') }}
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                class="flex justify-end space-x-1">
                <sm-button
                  v-if="slotProps.data?.permissions.canView"
                  v-tippy="$t('generic.buttons.view')"
                  preset="view-icon"
                  :href="slotProps.data?.routes.show" />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import InfoCard from "@/inertia/pages/reports/_partials/InfoCard.vue";

const props = defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
  aggregates: { type: Array, required: false, default: () => [] },
});

const aggregates = computed(() => {
  return props.aggregates.map(aggregate => ({
    ...aggregate,
    money: props.table?.aggregates[aggregate.type] ?? 0,
  }));
});

const tableFields = ["product_name", "customer", "amount", "unit_price", "weighted_avg_price", "payment_type", "sold", "cash_register", "warehouse"];
</script>
