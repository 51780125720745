<template>
  <div>
    <In-Head :title="$t('sales.stock.show.item_registrations.title')" />

    <sub-header :title="$t('sales.stock.show.item_registrations.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-table
        :table-data="$page.props.stocksByProduct"
        table-heading-key="sales.stock.show.item_registrations.table"
        :table-columns="tableColumns">
        >
        <template #warehouse="slotProps">
          {{ slotProps.data?.warehouse.label }}
        </template>
        <template #actions="slotProps">
          <div class="flex justify-end">
            <warehouse-move-modal :data="slotProps.data" />
          </div>
        </template>
      </sm-table>
    </sm-card>
  </div>
</template>

<script setup>
import WarehouseMoveModal from "./_partials/WarehouseMoveModal.vue";
const tableColumns = ref(["display_amount", "warehouse"]);
</script>
