<template>
  <div>
    <sm-button
      :icon="icon"
      :title="title"
      :loading="form.processing"
      size="icon"
      @click="confirm ? onConfirm() : onSubmit()" />
    <sm-confirm-dialog
      title="Confirm title"
      :accept-label="acceptLabel || $t('generic.yes')"
      :group="name" />
  </div>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import { useConfirm } from "primevue/useconfirm";

const props = defineProps({
  route: { type: String, required: true, default: "" },
  name: { type: String, required: true, default: null },
  method: { type: String, required: false, default: "post" },
  title: { type: String, required: false, default: "" },
  icon: { type: String, required: true, default: null },
  data: { type: Object, required: false, default: () => ({}) },
  confirm: { type: [Boolean, String], required: false, default: false },
  confirmBody: { type: String, required: false, default: null },
  acceptLabel: { type: String, required: false, default: null },
  cancelLabel: { type: String, required: false, default: null },
  only: { type: Array, required: false, default: null },
});

const emit = defineEmits(["onSuccess"]);

const form = useForm(props.data);

const conf = useConfirm();

const onConfirm = () => {
  conf.require({
    group: props.name,
    header: typeof props.confirm === "string" ? props.confirm : window.trans("generic.are_you_sure"),
    message: props.confirmBody ?? null,
    accept: () => onSubmit()
  });
};

const onSubmit = () => {
  form[props.method](props.route, {
    preserveScroll: true,
    preserveState: true,
    only: props.only ? props.only : ["table", "flash", "errors", "filter"],
    onSuccess: () => {
      emit("onSuccess");
    }
  });
};
</script>
