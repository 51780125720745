<template>
  <div>
    <In-Head :title="$t('reports.coach_salaries.title')" />

    <sub-header :title="$t('reports.coach_salaries.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <div class="p-4">
        <div class="flex justify-between">
          <h5>
            {{ $t('reports.coach_salaries.type.interval.title') }}
          </h5>
        </div>
        <div v-auto-animate>
          <div>
            <p>
              {{ $t('reports.coach_salaries.type.interval.info') }}
              <br>
              <small>* {{ $t('reports.coach_salaries.no_replacement') }}</small>
            </p>
            <hr>
            <div class="sm-row">
              <div class="w-4/12 sm-col">
                <sm-date-picker
                  v-model="startDate"
                  :max-date="endDate"
                  :label="$t('reports.coach_salaries.time.from')" />
              </div>
              <div class="w-4/12 sm-col">
                <sm-date-picker
                  v-model="endDate"
                  :min-date="startDate"
                  :label="$t('reports.coach_salaries.time.to')" />
              </div>
              <div class="sm-col lg:w-4/12 lg:pt-label">
                <sm-button
                  color="info"
                  size="input"
                  icon="filter"
                  @click="onFilter" />
              </div>
            </div>
          </div>
        </div>

        <info-card
          large
          :data="infoData" />
      </div>
      <sm-table
        :table-data="$page.props.coachSalaries"
        table-heading-key="reports.coach_salaries.table"
        :table-columns="tableColumns">
        <template #actions="slotProps">
          <sm-button
            v-tippy="$t('generic.details')"
            icon="clipboard"
            size="icon"
            :href="slotProps.data?.routes.show" />
        </template>
      </sm-table>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import InfoCard from "@/inertia/pages/reports/_partials/InfoCard.vue";
import { useForm, usePage } from "@inertiajs/vue3";
import { endOfMonth, startOfMonth } from "date-fns";

const props = defineProps({
  coachSalaries: { type: Array, required: false, default: () => [] },
  sumToPay: { type: String, required: false, default: null },
});

const tableColumns = ref(["name", "status", "own_classes_count", "substituted_classes_count", "average_participants", "own_classes_worth", "substituted_classes_worth", "sum_worth"]);

const startDate = ref(startOfMonth(new Date()));
const endDate = ref(endOfMonth(new Date()));

const infoData = {
  color: "teal",
  icon: "bar-chart-2",
  name: window.trans("reports.coach_salaries.sum"),
  data: props.sumToPay
};

const form = useForm({});
const page = usePage();

const onFilter = () => {
  console.log("onFilter");
  form
    .transform(() => ({
      from: useDateFormat(startDate.value, "form"),
      to: useDateFormat(endDate.value, "form"),
    }))
    .get(page.url.split("?")[0], {
      only: ["coachSalaries"],
      preserveScroll: true,
      preserveState: true,
    });
};
</script>
