<template>
  <div>
    <In-Head :title="$t('sales.installment_payments.title')" />

    <sub-header :title="$t('sales.installment_payments.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.create"
          old-link
          preset="add" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="$page.props.table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`sales.installment_payments.columns.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'customer'">
                <sm-avatar :user="slotProps.data?.customer" />
              </template>
              <template v-else-if="col === 'next_installment'">
                <template v-if="slotProps.data?.next_installment">
                  {{ useDateFormat(slotProps.data?.next_installment.date) }} - {{ slotProps.data?.next_installment.price }}
                </template>
                <span v-else>-</span>
              </template>
              <template v-else-if="col === 'created_at'">
                {{ useDateFormat(slotProps.data?.created_at, 'long') }}
              </template>
              <template v-else-if="col === 'active'">
                <sm-boolean-flag :value="slotProps.data?.active" />
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                class="flex justify-end space-x-1">
                <sm-form-button
                  v-if="slotProps.data?.permissions.canCancel"
                  v-tippy="$t('sales.installment_payments.cancel')"
                  icon="x-circle"
                  :route="slotProps.data?.routes.cancel"
                  :name="`${cancelInstallment}_${slotProps.data?.id}`"
                  method="post"
                  confirm
                />
                <sm-form-button
                  v-if="slotProps.data?.permissions.canReactivate"
                  v-tippy="$t('sales.installment_payments.reactivate')"
                  icon="play-circle"
                  :route="slotProps.data?.routes.reactivate"
                  :name="`${cancelInstallment}_${slotProps.data?.id}`"
                  method="post"
                  confirm
                />
                <sm-button
                  v-if="slotProps.data?.permissions.canEdit"
                  v-tippy="$t('generic.edit')"
                  preset="edit-icon"
                  old-link
                  :href="slotProps.data?.routes.edit" />
                <sm-button
                  v-tippy="$t('generic.show')"
                  icon="eye"
                  size="icon"
                  :href="slotProps.data?.routes.show" />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref(["customer", "ticket", "total_price", "installments", "next_installment", "created_at", "active"]);

</script>
