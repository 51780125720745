<template>
  <sm-modal
    :modal-is-visible="modalVisibility"
    :modal-title="$t('customers.tickets.modals.voucher.title')"
    @toggleModalVisibility="toggleAndResetModal">
    <form @submit.prevent="onSubmit">
      <sm-alert color="secondary">
        <span>{{ $t('customers.tickets.modals.voucher.help') }}</span>
      </sm-alert>
      <sm-input
        v-model="form.voucher"
        :label="$t('customers.tickets.modals.voucher.code_field')"
        name="voucher"
      />
    </form>
    <template #footer>
      <div class="flex-1">
        <sm-button
          :title="$t('customers.tickets.modals.voucher.generate')"
          @click="generateCode" />
      </div>
      <sm-button
        :title="$t('generic.cancel')"
        @click="toggleAndResetModal" />
      <sm-button
        color="primary"
        :title="$t('generic.save')"
        :disabled="!form.isDirty"
        :loading="form.processing"
        @click="onSubmit" />
    </template>
  </sm-modal>
</template>

<script setup>
import axios from "axios";
import { useForm, usePage } from "@inertiajs/vue3";

const page = usePage();

const props = defineProps({
  modalVisibility: { type: Boolean, required: true, default: false },
  modalData: { type: Object, required: false, default: null },
});

const emit = defineEmits(["toggleModalVisibility"]);

const form = useForm({
  voucher: "",
});

provide("form", form);

const onSubmit = () => {
  form
    .post(props.modalData.routes.addVoucher, {
      only: ["table", "customer", "errors", "flash"],
      onSuccess: () => {
        toggleAndResetModal();
      }
    });
};

const toggleAndResetModal = () => {
  emit("toggleModalVisibility");
  form.reset("voucher");
};

const generateCode = async () => {
  try {
    const response = await axios.post(page.props.routes.generateVoucher);
    form.voucher = response.data.data.code;
  } catch (e) {
    // TODO: toast
  }
};
</script>
