<template>
  <div>
    <sm-card header-title="Props">
      <div class="">
        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add the name attribute to the input<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> name<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">name="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-input
              label="Input with name prop"
              name="name" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add the id attribute to the input<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> id<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">id="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-input
              id="id"
              label="Input with id prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Define the input type<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> type<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> text<br>
              <strong class="inline-block pl-2 w-36">Available values:</strong> text, textarea<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">type="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-input
              type="text"
              label="Input with type text prop" />
            <sm-input
              type="textarea"
              label="Input with type textarea prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add error text and state to the input<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> error<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean, String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">error="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-input
              id="id"
              error="Some error text"
              label="Input with error prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add disabled state to input<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> disabled<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:disabled="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-input
              disabled
              label="Input with disabled prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add required state to input<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> required<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:required="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-input
              required
              label="Input with required prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add optional text to input<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> optional<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:optional="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-input
              optional
              label="Input with optional prop ->" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Set pattern to input<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> pattern<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">pattern="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-input
              pattern="[A-Za-z]{3}"
              label="Input with [A-Za-z]{3} pattern prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Add extra class to input when necessary<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> inputClasses<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:input-classes="..."</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-input
              input-classes="text-bo-red"
              value="Hey, i'm red"
              label="Input with inputClasses prop" />
          </div>
        </div>
        <hr>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Render the label inline before the input<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> inline<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:inline="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-input
              inline
              label="Input with inline prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Render the input with half width<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> half<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> Boolean<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> false<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">:half="true"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-input
              half
              label="Input with half prop" />
          </div>
        </div>
        <hr>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Set the height when the type is textarea<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> rows<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String, Number<br>
              <strong class="inline-block pl-2 w-36">Default:</strong> 5<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">rows="5"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-input
              type="textarea"
              rows="5"
              label="Textarea with rows prop" />
          </div>
        </div>
        <hr>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Set the mininum value when the type is number<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> min<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String, Number<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">min="5"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-input
              type="number"
              min="5"
              label="Number input with min prop" />
          </div>
          <hr>
        </div>

        <div class="sm-row">
          <div class="w-1/2 sm-col">
            <p class="mb-2">
              Set the maximum value when the type is number<br>
              <strong class="inline-block pl-2 w-36">Prop name:</strong> max<br>
              <strong class="inline-block pl-2 w-36">Type:</strong> String, Number<br>
              <strong class="inline-block pl-2 w-36">Example:</strong> <code class="inline-block"><pre class="text-xs">max="15"</pre></code>
            </p>
          </div>
          <div class="w-1/2 sm-col">
            <sm-input
              type="number"
              max="15"
              label="Number input with min prop" />
          </div>
        </div>
        <hr>
      </div>
    </sm-card>
  </div>
</template>
