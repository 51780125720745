<template>
  <div>
    <In-Head :title="$t('accessories.index.title')" />

    <sub-header :title="$t('accessories.index.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          v-if="$page.props.permissions.canCreate"
          :href="$page.props.routes.create"
          old-link
          preset="add" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`accessories.index.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'name'">
                {{ slotProps.data?.name }}
                <span
                  v-if="slotProps.data?.trashed"
                  class="text-bo-red">({{ $t('generic.deleted2') }})</span>
              </template>
              <template v-else-if="col === 'price'">
                <template v-if="slotProps.data?.sellable">
                  {{ usePriceFormat(slotProps.data?.price) }}
                </template>
                <template v-else>
                  {{ $t('sales.accessories.non_sellable') }}
                </template>
              </template>
              <template v-else-if="col === 'should_reclaim'">
                <sm-boolean-flag :value="slotProps.data?.should_reclaim" />
              </template>
              <template v-else-if="col === 'can_be_assigned_multiple_times'">
                <sm-boolean-flag :value="slotProps.data?.can_be_assigned_multiple_times" />
              </template>
              <template v-else-if="col === 'assignments_count'">
                <template v-if="slotProps.data?.assignments_count > 1">
                  <Link
                    :href="slotProps.data?.routes.assignedAccessory"
                    class="sm-badge sm-badge-info">
                    {{ slotProps.data?.assignments_count }} {{ $t('generic.count') }}
                  </Link>
                </template>
                <template v-else>
                  <template v-if="slotProps.data?.activeAssignment">
                    <Link
                      :href="slotProps.data?.routes.showCustomer"
                      class="sm-badge sm-badge-info">
                      {{ slotProps.data?.activeAssignment.customer.name }}
                    </Link>
                  </template>
                  <template v-else>
                    <div class="sm-badge sm-badge-success">
                      {{ $t('generic.available') }}
                    </div>
                  </template>
                </template>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                class="flex justify-end space-x-1">
                <sm-button
                  v-if="slotProps.data?.permissions.canView"
                  v-tippy="$t('generic.history')"
                  icon="list"
                  size="icon"
                  :href="slotProps.data?.routes.assignments" />
                <template v-if="!slotProps.data?.trashed">
                  <sm-button
                    v-if="slotProps.data?.permissions.canUpdate"
                    v-tippy="$t('generic.edit')"
                    preset="edit-icon"
                    old-link
                    :href="slotProps.data?.routes.edit" />
                  <sm-delete-button
                    v-if="slotProps.data?.permissions.canDelete"
                    :route="slotProps.data?.routes.delete"
                    name="deleteSeasonTicketSubscription"
                  />
                </template>
                <sm-form-button
                  v-else-if="slotProps.data?.permissions.canRestore"
                  v-tippy="$t('generic.restore')"
                  icon="rotate-ccw"
                  :route="slotProps.data?.routes.restore"
                  :name="`restoreAccesory_${slotProps.data.id}`"
                  method="post"
                  confirm
                />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { usePriceFormat } from "@/inertia/composables/global";
import { Link } from "@inertiajs/vue3";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref([
  "name", "price", "should_reclaim", "can_be_assigned_multiple_times", "assignments_count"
]);
</script>
