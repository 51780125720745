<template>
  <div>
    <In-Head :title="$t('customers.transactions.show.title', {name: $page.props.customer.name})" />

    <sub-header
      :title="$t('customers.transactions.show.title', {name: $page.props.customer.name})"
      :export-route="$page.props.routes.export"
    >
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.back"
          preset="back"
          icon="arrow-left" />
        <withdraw-modal />
      </template>
    </sub-header>

    <customers-nav-tabs />

    <sm-card no-padding>
      <transaction-table
        :table="table"
        :sorts="sorts"
        :table-fields="tableFields" />
    </sm-card>
  </div>
</template>

<script setup>
import CustomersNavTabs from "@/inertia/pages/customers/show/_partials/CustomersNavTabs.vue";
import TransactionTable from "../../../sales/_partials/TransactionTable.vue";
import WithdrawModal from "./_modals/WithdrawModal.vue";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref(["paid", "id", "ntak_success", "payer", "amount", "status", "payment_type", "created_by", "paid_at", "invoice"]);
</script>
