<template>
  <div>
    <In-Head :title="$t('sales.stock_registrations.index.title')" />

    <sub-header :title="$t('sales.stock_registrations.index.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          v-if="$page.props.permissions.canCreate"
          :href="$page.props.routes.create"
          old-link
          preset="add" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :row-class="({ is_cancelled }) => is_cancelled ? '!bg-bo-red/20 hover:!bg-bo-red/30': null"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`sales.stock_registrations.index.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'date'">
                {{ useDateFormat(slotProps.data?.date) }}
              </template>
              <template v-else-if="col === 'abbr'">
                <span class="text-xxs">{{ $t('generic.abbr.net') }}: </span>
                <strong>{{ usePriceFormat(slotProps.data?.total_net) }}</strong><br>
                <span class="text-xxs">{{ $t('generic.abbr.gross') }}: </span>
                <strong>{{ usePriceFormat(slotProps.data?.total_gross) }}</strong><br>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                v-if="slotProps.data?.permissions.view && !slotProps.data?.trashed"
                class="flex justify-end space-x-1">
                <sm-button
                  v-tippy="$t('generic.show')"
                  preset="view-icon"
                  :href="slotProps.data?.routes.show" />
                <sm-button
                  v-tippy="$t('generic.show_pdf')"
                  icon="file-text"
                  size="icon"
                  old-link
                  target="_blank"
                  :href="slotProps.data?.routes.pdf" />
                <sm-button
                  v-if="slotProps.data?.is_cancelled"
                  v-tippy="$t('generic.show_storno')"
                  icon="rotate-ccw"
                  size="icon"
                  old-link
                  :href="slotProps.data?.routes.show_storno" />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat, usePriceFormat } from "@/inertia/composables/global";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref([
  "invoice_number", "receipt_number", "date", "contact", "note", "abbr", "created_by"
]);
</script>
