<template>
  <div>
    <In-Head :title="$t('backoffice.pages.installment-payments.show')" />

    <sub-header :title="$t('backoffice.pages.installment-payments.show')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          preset="back"
          icon="arrow-left"
          :href="$page.props.routes.back" />
        <sm-button
          v-if="$page.props.permissions.canEdit"
          preset="edit"
          old-link
          :href="$page.props.routes.edit" />
        <sm-form-button
          v-if="$page.props.permissions.canCancel"
          :title="$t('sales.installment_payments.cancel')"
          :route="$page.props.routes.cancel"
          name="cancelInstallment"
          method="post"
          icon="x-circle"
          @onSuccess="onSuccess"
        />
        <sm-form-button
          v-if="$page.props.permissions.canReactivate"
          :title="$t('sales.installment_payments.reactivate')"
          :route="$page.props.routes.reactivate"
          name="reactivateInstallment"
          method="post"
          icon="play-circle"
          @onSuccess="onSuccess"
        />
      </template>
    </sub-header>

    <sm-card
      :header-title="$t('sales.inventory.show.data.title')">
      <table class="sm-table sm-table-no-heading sm-table-bordered">
        <tbody>
          <tr>
            <td><strong>{{ $t('sales.installment_payments.fields.customer') }}</strong></td>
            <td>{{ installmentPayment.customer }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('sales.installment_payments.fields.ticket') }}</strong></td>
            <td>{{ installmentPayment.ticket }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('sales.installment_payments.fields.total_price') }}</strong></td>
            <td>{{ installmentPayment.total_price }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('sales.installment_payments.fields.active') }}</strong></td>
            <td>
              <sm-boolean-flag :value="installmentPayment.active" />
            </td>
          </tr>
        </tbody>
      </table>
    </sm-card>

    <sm-card
      no-padding
      :header-title="$t('sales.installment_payments.fields.installments')">
      <sm-table
        :table-data="$page.props.installments"
        table-heading-key="sales.installment_payments.installments.fields"
        :row-classes="{'text-bo-red': 'trashed'}"
        :table-columns="simpleTableColumns">
        <template #index="slotProps">
          {{ slotProps.data?.rowIndex + 1 }}
        </template>
        <template #actions="slotProps">
          <div class="flex items-center justify-end space-x-2">
            <sm-button
              v-if="slotProps.data?.permissions.canView"
              v-tippy="$t('sales.transactions.show_button')"
              icon="log-out"
              size="icon"
              old-link
              :href="slotProps.data?.routes.show" />
            <settle-modal
              v-if="slotProps.data?.permissions.canSettle"
              :slot-props="slotProps"
              @onSuccess="onSuccess" />
          </div>
        </template>
      </sm-table>
    </sm-card>
  </div>
</template>

<script setup>
import SettleModal from "./_partials/SettleModal.vue";
import { router, usePage } from "@inertiajs/vue3";

const page = usePage();
const installmentPayment = computed(() => page.props.installmentPayment);

const simpleTableColumns = ref(["index", "price", "due_date", "valid_until", "paid"]);

const onSuccess = () => {
  router.reload({only: ["installments", "installmentPayment", "permissions"]}, { preserveState: true });
};
</script>
