<template>
  <div
    ref="toggleButtonContainer"
    class="relative">
    <sm-button
      v-ripple
      type="button"
      :size="size"
      :color="color"
      :disabled="disabled"
      :loading="loading"
      class="p-ripple"
      @click="onClick">
      <span>{{ title }}</span>
      <vue-feather
        type="chevron-down"
        class="w-3 h-3 ml-0.5" />
    </sm-button>
    <OverlayPanel
      ref="overlayPanel"
      :base-z-index="3">
      <ul
        class="p-1.5 mt-0.5 text-brand-gray-800 text-left list-none bg-white border border-brand-gray-200 rounded-lg relative z-[1000]">
        <slot name="firstOption" />
        <li
          v-for="option in options"
          :key="option.id">
          <template v-if="checkVisibility(option)">
            <Link
              v-if="actionData?.routes[option?.to] || option.to"
              :href="actionData?.routes[option?.to] ? actionData?.routes[option?.to] : option.to"
              class="sm-dropdown-item"
              :class="[{ 'active': option?.to === location.href }, option?.class]">
              <vue-feather
                v-if="option.icon"
                :type="option.icon"
                size="14" />
              <span>{{ option.label || option.name }}</span>
            </Link>
            <a
              v-if="option.toLegacyRoute"
              :href="actionData?.routes[option?.toLegacyRoute] ? actionData?.routes[option?.toLegacyRoute] : option.toLegacyRoute"
              class="sm-dropdown-item"
              :class="[{ 'active': option?.toLegacyRoute === location.href }, option?.class]">
              <vue-feather
                v-if="option.icon"
                :type="option.icon"
                size="14" />
              <span>{{ option.label || option.name }}</span>
            </a>
            <button
              v-if="option.action"
              type="button"
              class="sm-dropdown-item"
              :class="option?.class"
              @click="$nextTick(() => option.action(actionData))">
              <vue-feather
                v-if="option.icon"
                :type="option.icon"
                size="14" />
              <span>{{ option.label || option.name }}</span>
            </button>
          </template>
        </li>
        <slot name="lastOption" />
      </ul>
    </OverlayPanel>
  </div>
</template>

<script setup>
import { Link } from "@inertiajs/vue3";
import { onClickOutside, useBrowserLocation } from "@vueuse/core";
import { usePrimeVue } from "primevue/config";

const $primevue = usePrimeVue();

const location = useBrowserLocation();

defineExpose({
  $primevue
});

const props = defineProps({
  title: { type: String, required: false, default: "" },
  options: { type: Array, required: false, default: () => [] },
  disabled: { type: Boolean, required: false, default: false },
  loading: { type: Boolean, required: false, default: false },
  // styling props
  size: { type: String, required: false, default: "xs" },
  color: { type: String, required: false, default: "" },
  actionData: { type: Object, required: false, default: null },
});

const active = ref(false);

const overlayPanel = ref();
const onClick = () => {
  overlayPanel.value.toggle(event);
};

const checkVisibility = (option) => {
  if (option && Object.prototype.hasOwnProperty.call(option, "visible")) {
    if (typeof option.visible === "function") {
      return option.visible(props.actionData);
    } else {
      return option.visible;
    }
  }

  return true;
};

const toggleButtonContainer = ref();
onClickOutside(toggleButtonContainer, () => active.value = false);
</script>
