<template>
  <div>
    <In-Head :title="$t('sales.warehouses.index.title')" />

    <sub-header :title="$t('sales.warehouses.index.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          v-if="$page.props.permissions.canCreate"
          :href="$page.props.routes.create"
          old-link
          preset="add" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`sales.warehouses.index.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'name'">
                {{ slotProps.data?.name }}
                <span
                  v-if="slotProps.data?.trashed"
                  class="text-bo-red">({{ $t('generic.deleted2') }})</span>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                v-if="!slotProps.data?.trashed"
                class="flex justify-end space-x-1">
                <sm-button
                  v-if="slotProps.data?.permissions.canUpdate"
                  v-tippy="$t('generic.edit')"
                  preset="edit-icon"
                  old-link
                  :href="slotProps.data?.routes.edit" />
                <template
                  v-if="slotProps.data?.permissions.canDelete"
                >
                  <sm-delete-button
                    v-tippy="slotProps.data?.has_item_on_stock ? $t('sales.warehouses.index.cannot_delete_non_empty') : null"
                    :disabled="slotProps.data?.has_item_on_stock"
                    :route="slotProps.data?.routes.delete"
                    :name="`deleteWarehouse_${slotProps.data?.id}`"
                  />
                </template>
              </div>

              <template v-else>
                <sm-form-button
                  v-if="slotProps.data?.permissions.canRestore"
                  icon="rotate-ccw"
                  :route="slotProps.data?.routes.restore"
                  :name="`restoreWarehouse_${slotProps.data?.id}`"
                  method="post"
                  confirm
                />
              </template>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref([
  "name"
]);

</script>
