<template>
  <div>
    <In-Head :title="$t('sales.stock_registrations.show.storno.index.title')" />

    <sub-header :title="$t('sales.stock_registrations.show.storno.index.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card
      :header-title="$t('sales.stock_registrations.show.data.title')">
      <table class="sm-table sm-table-no-heading sm-table-bordered">
        <tbody>
          <tr>
            <td><strong>{{ $t('sales.stock_registrations.show.data.storno_receipt_number') }}</strong></td>
            <td>{{ stockRegistration.stock_registration_storno?.receipt_number }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('sales.stock_registrations.show.data.receipt_number') }}</strong></td>
            <td>
              <Link :href="$page.props.routes.show_stock_registration">
                {{ stockRegistration.receipt_number }}
              </Link>
            </td>
          </tr>
          <tr>
            <td><strong>{{ $t('sales.stock_registrations.show.data.invoice_number') }}</strong></td>
            <td>{{ stockRegistration.invoice_number }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('sales.stock_registrations.show.data.warehouse') }}</strong></td>
            <td>{{ stockRegistration.warehouse?.name }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('sales.stock_registrations.show.data.date') }}</strong></td>
            <td>{{ useDateFormat(stockRegistration.date) }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('sales.stock_registrations.show.data.contact') }}</strong></td>
            <td>{{ stockRegistration.contact?.name || '-' }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('sales.stock_registrations.show.data.created_by') }}</strong></td>
            <td>{{ stockRegistration.created_by?.name || '-' }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('sales.stock_registrations.show.data.note') }}</strong></td>
            <td>{{ stockRegistration.note }}</td>
          </tr>
        </tbody>
      </table>
    </sm-card>

    <sm-card
      no-padding
      :header-title="$t('sales.stock_registrations.show.products.title')">
      <sm-table
        :table-data="simpleTableData"
        table-heading-key="sales.stock_registrations.show.products.table"
        :row-classes="{'text-bo-red': 'trashed' }"
        :table-columns="simpleTableColumns" />
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import { Link, usePage } from "@inertiajs/vue3";

const page = usePage();
const stockRegistration = ref(page.props.stockRegistration);

const simpleTableColumns = ref(["name", "identification_number", "display_amount", "acquisition_price", "acquisition_price_net", "acquisition_price_tax"]);

const simpleTableData = ref(page.props.products);
</script>
