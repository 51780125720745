<template>
  <div>
    <h4>{{ $t('NTAK adatszolgáltatás') }}</h4>
    <sm-card no-padding>
      <sm-table
        :table-data="syncs"
        table-heading-key="ntak.table"
        :table-columns="tableColumns">
        <template #status="slotProps">
          <template v-if="slotProps.data.status === 'successful'">
            <sm-boolean-flag
              v-tippy="$t('ntak.status_icon_tooltip.successful')"
              :value="true"
            />
          </template>
          <template v-else-if="slotProps.data.status === 'processing'">
            <vue-feather
              v-tippy="$t('ntak.status_icon_tooltip.processing')"
              type="clock"
              size="16"
            />
          </template>
          <template v-else-if="slotProps.data.status === 'failed'">
            <sm-boolean-flag
              v-tippy="$t('ntak.status_icon_tooltip.failed')"
              :value="false"
            />
          </template>
        </template>
        <template #sent_at="slotProps">
          {{ slotProps.data.sent_at ? useDateFormat(slotProps.data.sent_at, 'long') : '' }}
        </template>
        <template #completed_at="slotProps">
          {{ slotProps.data.completed_at ? useDateFormat(slotProps.data.completed_at, 'long') : '' }}
        </template>
      </sm-table>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";

defineProps({
  syncs: { type: Array, required: true },
});

const tableColumns = ref(["status", "status_label", "ntak_id", "ntak_status", "sent_at", "completed_at"]);
</script>
