import { Inertia } from "@inertiajs/inertia";
import Cookies from "js-cookie";

// check the operating system (good for fancy overflows)
function checkOs() {
  let os = "Unknown";
  if (navigator.appVersion.indexOf("Win") != -1) os = "windows";
  if (navigator.appVersion.indexOf("Mac") != -1) os = "mac";
  if (navigator.appVersion.indexOf("Linux") != -1) os = "linux";
  document.querySelector("body").classList.add(os);
}

// sidebar state
function sidebarOpen() {
  const opener = document.querySelector(".sidebar-opener");

  if (!opener) {
    return;
  }

  const sidebar = document.querySelector(".sidebar");

  opener.addEventListener("click", () => {
    sidebar.classList.toggle("closed");
    sidebar.classList.toggle("sidebar-closed");
    if (Cookies.get("smc-sidebar-closed")) {
      Cookies.remove("smc-sidebar-closed");
    } else {
      Cookies.set("smc-sidebar-closed", true);
    }
  });

  if (window.innerWidth < 768) {
    sidebar.classList.add("closed");
    sidebar.classList.add("sidebar-closed");
    Cookies.set("smc-sidebar-closed", true);
  }
}

// sidebar submenu state
function sidebarMenuOpen() {
  const sidebar = document.querySelector(".sidebar");
  const sidebarMenu = document.querySelector(".sidebar-menu");
  const sidebarLinks = document.querySelectorAll(".sidebar-link-opener");
  const activeMenuLink = document.querySelector(".sidebar-menu > li.active");
  const activeSubmenuLink = document.querySelector(".sidebar-menu li ul li.active");

  if (!sidebar) {
    return;
  }

  sidebarLinks.forEach((elem) => {
    elem.addEventListener("click", () => {
      elem.parentElement.classList.toggle("open");
    });
  });

  if (activeMenuLink) {
    activeMenuLink.scrollIntoView();
  }

  if (activeSubmenuLink) {
    const activeSubmenu = activeSubmenuLink.parentElement.parentElement;
    activeSubmenu.scrollIntoView();
    activeSubmenu.classList.add("open");
    activeSubmenu.classList.add("has-active-submenu");
  }

  sidebarMenu.addEventListener("mouseover", () => {
    if (sidebar.classList.contains("sidebar-closed")) {
      sidebar.classList.remove("closed");
    }
  });

  sidebarMenu.addEventListener("mouseleave", () => {
    if (sidebar.classList.contains("sidebar-closed")) {
      sidebar.classList.add("closed");
    }
  });
}

function pageChange() {

  Inertia.on("start", (event) => {
    const el = document.querySelector(`[href='${event.detail.visit.url.href}']`)?.parentElement;

    if (el && !el.classList.contains("active")) {
      if (document.querySelector(".sidebar-menu .active")) {
        document.querySelector(".sidebar-menu .active").classList.remove("active");
      }
      el.classList.add("active");
    }
  });
}

pageChange();

document.addEventListener("DOMContentLoaded", () => {

  checkOs();

  sidebarOpen();

  sidebarMenuOpen();

});
