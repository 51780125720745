<template>
  <div>
    <In-Head :title="$t('transactions.index.title')" />

    <sub-header :title="$t('transactions.index.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header-class="col === 'balance' ? 'flex justify-end' : ''"
            :header="$t(`transactions.index.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template
                v-if="col === 'customer' || col === 'created_by'">
                <span v-if="typeof(slotProps.data?.[col]) === 'string'">{{ slotProps.data?.[col] }}</span>
                <sm-avatar
                  v-else
                  :user="slotProps.data?.[col]" />
              </template>
              <template
                v-else-if="col === 'ntak' || col === 'paid'">
                <sm-boolean-flag :value="slotProps.data?.[col]" />
              </template>
              <template
                v-else-if="col === 'amount'">
                <span
                  v-if="slotProps.data?.refunded"
                  class="font-medium text-bo-red">-{{ usePriceFormat(slotProps.data?.amount) }}</span>
                <span
                  v-else
                  class="font-medium text-bo-green">{{ usePriceFormat(slotProps.data?.amount) }}</span>
              </template>
              <template
                v-else-if="col === 'paid_at' || col === 'locked_at'">
                {{ useDateFormat(slotProps.data?.[col], 'long') }}
              </template>
              <template
                v-else-if="col === 'status'">
                <span
                  v-if="slotProps.data?.trashed"
                  class="font-bold text-bo-red">{{ $t('generic.deleted2') }}</span>
                <span v-else>{{ slotProps.data?.status }}</span>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                class="flex justify-end space-x-1">
                <sm-button
                  v-if="slotProps.data?.permissions.canView"
                  v-tippy="$t('generic.buttons.view')"
                  preset="view-icon"
                  :href="slotProps.data?.routes.show" />
                <sm-button
                  v-if="slotProps.data?.permissions.canUpdate"
                  v-tippy="$t('generic.edit')"
                  preset="edit-icon"
                  old-link
                  :href="slotProps.data?.routes.edit" />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat, usePriceFormat } from "@/inertia/composables/global";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => { } },
});

const tableFields = ["paid", "id", "ntak", "customer", "amount", "status", "payment_type", "paid_at", "invoice", "created_by"];
</script>
