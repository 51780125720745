<template>
  <div>
    <In-Head :title="$t('sales.inventory.show.title')" />

    <sub-header :title="$t('sales.inventory.show.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card
      :header-title="$t('sales.inventory.show.data.title')">
      <table class="sm-table sm-table-no-heading sm-table-bordered">
        <tbody>
          <tr>
            <td><strong>{{ $t('sales.inventory.show.data.created_by') }}</strong></td>
            <td>{{ inventory.created_by }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('sales.inventory.show.data.created_at') }}</strong></td>
            <td>{{ useDateFormat(inventory.created_at) }}</td>
          </tr>
        </tbody>
      </table>
    </sm-card>

    <sm-card
      no-padding
      :header-title="$t('sales.inventory.show.products.title')">
      <sm-table
        :table-data="productsTableData"
        table-heading-key="sales.inventory.show.products.table"
        :row-classes="{'sm-table-success': 'isMatching', 'sm-table-warning': 'isExcess','sm-table-danger': 'isMissing'}"
        :table-columns="productsTableColumns">
        <template #expected_amount="slotProps">
          {{ slotProps.data.expected_amount }} {{ slotProps.data.unit }}
        </template>
        <template #actual_amount="slotProps">
          {{ slotProps.data.actual_amount }} {{ slotProps.data.unit }}
        </template>
        <template #difference="slotProps">
          <template v-if="slotProps.data.isMissing">
            {{ slotProps.data.difference }} {{ slotProps.data.unit }}
          </template>
          <template v-else>
            -
          </template>
        </template>
        <template #revenue="slotProps">
          <template v-if="slotProps.data.isExcess">
            <Link
              v-if="slotProps.data.stock_registration_id"
              :href="slotProps.data.routes.show_stock_registration"
              class="text-bo-blue">
              {{ slotProps.data.difference_abs }} {{ slotProps.data.unit }}
            </Link>
            <span
              v-else
              class="text-bo-gray-400">
              {{ slotProps.data.difference_abs }} {{ slotProps.data.unit }}
            </span>
          </template>
          <template v-else>
            -
          </template>
        </template>
      </sm-table>
      <div class="container pt-12 lg:px-10 xl:px-0">
        <div class="px-8 mt-4">
          <div class="justify-between sm-row">
            <difference-card
              :title="$t('cafeteria.inventory.columns.sum_of_difference')"
              :price="inventory.sumOfDifferences.byPrice" />
            <difference-card
              :title="$t('cafeteria.inventory.columns.missing_value')"
              :price="inventory.missingValue.byPrice"
              color="text-bo-red" />
            <difference-card
              :title="$t('cafeteria.inventory.columns.excess_value')"
              :price="inventory.excessValue.byPrice"
              color="text-bo-yellow-600" />
          </div>

          <hr>
          <h4>{{ $t('cafeteria.inventory.headings.with_weighted_average') }}</h4>

          <div class="justify-between sm-row">
            <difference-card
              :title="$t('cafeteria.inventory.columns.sum_of_difference')"
              :avg="inventory.sumOfDifferences.byWeightedAvg"
              :avg-gross="inventory.sumOfDifferences.byWeightedAvgGross" />
            <difference-card
              :title="$t('cafeteria.inventory.columns.missing_value')"
              :avg="inventory.missingValue.byWeightedAvg"
              :avg-gross="inventory.missingValue.byWeightedAvgGross"
              color="text-bo-red" />
            <difference-card
              :title="$t('cafeteria.inventory.columns.excess_value')"
              :avg="inventory.excessValue.byWeightedAvg"
              :avg-gross="inventory.excessValue.byWeightedAvgGross"
              color="text-bo-yellow-600" />
          </div>
        </div>
      </div>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import { Link, usePage } from "@inertiajs/vue3";
import DifferenceCard from "../../_partials/DifferenceCard.vue";

const page = usePage();
const inventory = ref(page.props.inventory);

const productsTableColumns = ref(["name", "expected_amount", "actual_amount", "difference", "revenue", "price"]);

const productsTableData = ref(page.props.inventoryItems);
</script>
