<script>
import { format } from "date-fns";
import AbstractCrudFilterType from "../utility/AbstractCrudFilterType";

export default {
  mixins: [AbstractCrudFilterType],
  data() {
    return {
      defaults: {
        operator: this.data.filter.options.defaultOperator,
        term: this.format(new Date()),
      },

      availableOperators: ["=", "<>", "<", "<=", ">", ">="],
      defaultIcon: "calendar",
    };
  },
  computed: {
    filterData() {
      return {
        term: this.data.data.term,
        operator: this.data.data.operator,
      };
    },
    displayableValue() {
      const label = format(new Date(this.data.data.term), "yyyy. MM. dd.");

      return `: ${label}`;
    }
  },
  methods: {
    format(date) {
      return format(new Date(date), "yyyy-MM-dd");
    }
  },
  render() {
    return this.renderAbstract([
      this.createDiv("sm-col w-2/12", [
        this.createSelect(
          [this.data.data, "operator"],
          this.availableOperators.map(t => ({ label: "crud_filter." + t, value: t }))
        ),
      ]),
      this.createDiv("sm-col w-6/12", [
        this.createDiv("sm-input-group", [
          this.createDiv("sm-input-group-prepend", [
            this.createIcon(this.icon)
          ]),
          this.createDatePicker([this.data.data, "term"], "text", "input"),
        ]),
      ]),
    ]);
  },
};
</script>
