<template>
  <div>
    <In-Head :title="$t('sales.inventory.index.title')" />

    <sub-header :title="$t('sales.inventory.index.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          v-if="$page.props.permissions.canCreate"
          :href="$page.props.routes.create"
          old-link
          preset="add" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`cafeteria.inventory.columns.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'date'">
                {{ useDateFormat(slotProps.data?.date, 'long') }}
                <div
                  v-if="slotProps.data?.draft"
                  class="ml-1 sm-badge sm-badge-info">
                  {{ $t('sales.inventory.index.draft') }}
                </div>
              </template>
              <template v-else-if="col === 'created_by'">
                <sm-avatar :user="slotProps.data?.created_by" />
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                v-if="!slotProps.data?.trashed"
                class="flex justify-end space-x-1">
                <sm-button
                  v-if="!slotProps.data?.draft"
                  v-tippy="$t('generic.show')"
                  preset="view-icon"
                  :href="slotProps.data?.routes.show" />
                <template v-else>
                  <sm-button
                    v-tippy="$t('generic.edit')"
                    preset="edit-icon"
                    old-link
                    :href="slotProps.data?.routes.edit" />
                  <sm-delete-button
                    :route="slotProps.data?.routes.delete"
                    name="deleteInventory"
                  />
                </template>
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref([
  "date", "warehouse", "created_by", "sum_of_difference", "missing_value", "excess_value"
]);
</script>
