<template>
  <div>
    <In-Head :title="$t('backoffice.pages.reports.debts')" />

    <sub-header :title="$t('backoffice.pages.reports.debts')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>


    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header-class="col === 'balance' ? 'flex justify-end' : ''"
            :header="$t(`reports.debts.index.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template
              v-if="col === 'customer'"
              #body="slotProps">
              <sm-avatar :user="slotProps.data?.customer" />
            </template>
            <template
              v-else-if="col === 'balance'"
              #body="slotProps">
              <div class="text-right">
                {{ usePriceFormat(slotProps.data?.balance) }}
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { usePriceFormat } from "@/inertia/composables/global";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => { } },
});

const tableFields = ["customer", "balance"];
</script>
