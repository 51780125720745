<template>
  <div>
    <In-Head :title="$t('sales.inventory.scrap.show.title')" />

    <sub-header :title="$t('sales.inventory.scrap.show.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card>
      <table class="sm-table sm-table-no-heading sm-table-bordered">
        <tbody>
          <tr>
            <td><strong>{{ $t('sales.inventory.scrap.table.created_by') }}</strong></td>
            <td>{{ scrap.created_by }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('sales.inventory.scrap.table.created_at') }}</strong></td>
            <td>{{ useDateFormat(scrap.created_at) }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('sales.inventory.scrap.table.total_value') }}</strong></td>
            <td>{{ scrap.total_value }}</td>
          </tr>
          <tr>
            <td><strong>{{ $t('sales.inventory.scrap.table.note') }}</strong></td>
            <td>{{ scrap.note || '-' }}</td>
          </tr>
        </tbody>
      </table>
    </sm-card>

    <sm-card
      no-padding
      :header-title="$t('sales.inventory.scrap.show.products')">
      <sm-table
        :table-data="simpleTableData"
        table-heading-key="sales.inventory.scrap.show.table"
        :table-columns="simpleTableColumns" />
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import { usePage } from "@inertiajs/vue3";

const page = usePage();
const scrap = ref(page.props.scrap);

const simpleTableColumns = ref(["name", "amount", "weighted_average_price", "price"]);

const simpleTableData = ref(page.props.scrapItems);
</script>
