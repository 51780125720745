<template>
  <div>
    <In-Head :title="$page.props.locker.name" />

    <sub-header :title="$page.props.locker.name">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`lockers.assignments.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'customer'">
                <sm-avatar
                  :user="{
                    profile_url: slotProps.data?.routes.showCustomer,
                    avatar: slotProps.data?.customer.avatar,
                    name: slotProps.data?.customer.name,
                  }" />
              </template>
              <template v-else-if="col === 'created_at' || col === 'deleted_at'">
                {{ useDateFormat(slotProps.data?.[col], 'long') }}
              </template>
              <template v-else-if="col === 'rental'">
                <sm-boolean-flag :value="slotProps.data?.rental" />
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref(["customer", "created_at", "deleted_at", "rental", "deleted_by"]);

</script>
