<template>
  <div>
    <In-Head :title="$t('sales.stock.show.product_warehouses.title')" />

    <sub-header :title="$t('sales.stock.show.product_warehouses.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-table
        :table-data="$page.props.stocksByProduct"
        table-heading-key="sales.stock.show.product_warehouses.table"
        :row-classes="{'sm-table-danger': 'is_scrap_warehouse'}"
        :table-columns="tableColumns">
        />
      </sm-table>
    </sm-card>
  </div>
</template>

<script setup>
const tableColumns = ref(["name", "warehouse", "display_amount"]);
</script>
