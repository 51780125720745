<template>
  <Link
    v-if="href !== '' && !oldLink"
    v-ripple
    link
    :href="href"
    :disabled="loading || disabled"
    :as="as"
    class="p-ripple"
    :class="customClasses">
    <slot v-if="$slots.default" />
    <template v-else>
      <sm-button-inside />
    </template>
  </Link>
  <a
    v-else-if="oldLink"
    :href="href"
    :disabled="loading || disabled"
    :class="customClasses">
    <slot v-if="$slots.default" />
    <template v-else>
      <sm-button-inside />
    </template>
  </a>
  <button
    v-else
    v-ripple
    :type="type"
    :disabled="loading || disabled"
    class="p-ripple"
    :class="customClasses"
    @click="onClick">
    <slot v-if="$slots.default" />
    <template v-else>
      <sm-button-inside />
    </template>
  </button>
</template>

<script setup>
import { Link } from "@inertiajs/vue3";
import { usePrimeVue } from "primevue/config";
import SmButtonInside from "./buttons/SmButtonInside.vue";

const emit = defineEmits(["click"]);

const $primevue = usePrimeVue();
defineExpose({
  $primevue
});

const props = defineProps({
  href: { type: String, required: false, default: "" },
  as: { type: String, required: false, default: "a" },
  type: { type: String, required: false, default: "button" },
  disabled: { type: Boolean, required: false, default: false },
  title: { type: String, required: false, default: "" },
  hotkey: { type: String, required: false, default: "" },
  loading: { type: Boolean, required: false, default: false },
  // styling props
  size: { type: String, required: false, default: "xs" },
  color: { type: String, required: false, default: "" },
  icon: { type: String, required: false, default: "" },
  iconPosition: { type: String, required: false, default: "left" },
  preset: { type: String, required: false, default: "" },
  oldLink: { type: Boolean, required: false, default: false },
});

const presetIcons = computed(() => {
  let icon;
  if (props.preset === "add") {
    icon = "plus-circle";
  } else if (props.preset === "delete" || props.preset === "delete-icon") {
    icon = "trash-2";
  } else if (props.preset === "edit" || props.preset === "edit-icon") {
    icon = "edit";
  } else if (props.preset === "view-icon") {
    icon = "eye";
  } else {
    icon = "";
  }
  return icon;
});

const computedTitle = computed(() => {
  let title = props.title;
  if (props.preset === "add") {
    title = window.trans("generic.buttons.add");
  } else if (props.preset === "delete") {
    title = window.trans("generic.buttons.delete");
  } else if (props.preset === "edit") {
    title = window.trans("generic.buttons.edit");
  } else if (props.preset === "save") {
    title = window.trans("generic.buttons.save");
  } else if (props.preset === "back") {
    title = window.trans("generic.buttons.back");
  }
  return title;
});

const customClasses = computed(() => {
  let size = "";
  let color = "";

  if (props.preset === "") {
    size = `sm-btn-${props.size}`;
    color = props.color !== "" ? `sm-btn-${props.color}` : "";
  } else if (props.preset === "add" || props.preset === "save") {
    size = "sm-btn-xs";
    color = props.color || "sm-btn-primary";
  } else if (props.preset === "delete") {
    size = "sm-btn-xs";
    color = "sm-btn-danger-outline";
  } else if (props.preset === "delete-icon") {
    size = "sm-btn-icon";
    color = "sm-btn-danger";
  } else if (props.preset === "edit" || props.preset === "back") {
    size = "sm-btn-xs";
  } else if (props.preset === "edit-icon" || props.preset === "view-icon") {
    size = "sm-btn-icon";
  }
  return `sm-btn ${size} ${color} p-ripple`;
});

function onClick() {
  emit("click");
}

provide("props", props);
provide("presetIcons", presetIcons);
provide("computedTitle", computedTitle);

</script>

<style scoped>
.loader-enter-active,
.loader-leave-active {
  @apply transition-all origin-center;
}

.loader-enter-from,
.loader-leave-to {
  @apply opacity-0 scale-0 w-0;
}

.loader-enter-to,
.loader-leave-from {
  @apply opacity-100 scale-100 w-4;
}
</style>
