<script>
import { toRaw } from "vue";
import AbstractCrudFilterType from "../utility/AbstractCrudFilterType";

export default {
  mixins: [AbstractCrudFilterType],
  data() {
    return {
      defaults: {},
      options: this.data.filter?.options?.options || [],

      preset: null,
    };
  },
  computed: {
    filterData() {
      return {
        term: this.data.data.term
      };
    },
    displayableValue() {
      let label = "";

      if (this.usingGroups) {
        const values = Object.values(toRaw(this.availableOptions));
        const newValues = values.flatMap(values => {
          return Object.keys(values)
            .map(key => {
              return { value: key, label: values[key] };
            });
        });

        label = newValues.find(o => o.value === this.data.data.term).label;
      } else {
        label = this.availableOptions.find(o => o.value === this.data.data.term).label;
      }

      return `: ${label}`;
    },
    availableOptions() {
      let options = [];

      if (this.data.filter?.options?.optionGroups) {
        options = this.data.filter?.options?.optionGroups;
      } else {
        options = Array.isArray(this.options)
          ? this.options
          : Object.entries(this.options).map(([value, label]) => ({ value, label }));
      }

      return options;
    },
    usingGroups() {
      return this.data.filter?.options?.optionGroups;
    }
  },
  render() {
    return this.renderAbstract([
      this.createDiv("sm-col w-8/12", [
        this.createDiv("sm-input-group", [
          this.icon ? this.createDiv("sm-input-group-prepend", [
            this.createIcon(this.icon)
          ]) : null,
          this.createSelect([this.data.data, "term"], this.availableOptions, "", [], this.usingGroups),
        ]),
      ]),
    ]);
  },
};
</script>
