<template>
  <div>
    <In-Head :title="$t('reports.cancelled_appointments.title')" />

    <sub-header :title="$t('reports.cancelled_appointments.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>


    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header-class="col === 'balance' ? 'flex justify-end' : ''"
            :header="$t(`reports.cancelled_appointments.columns.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template
                v-if="col === 'customer'">
                <sm-avatar :user="slotProps.data?.customer" />
              </template>
              <template
                v-else-if="col === 'date'">
                {{ useDateRangeFormat(slotProps.data?.date.from, slotProps.data?.date.to) }}
                <span v-if="slotProps.data?.service">({{ slotProps.data?.service }})</span>
              </template>
              <template
                v-else-if="col === 'added_by'">
                <span v-if="slotProps.data?.added_by === slotProps.data?.user_id">{{ $t('reports.cancelled_appointments.added_by_user') }}</span>
                <span v-else>{{ $t('reports.cancelled_appointments.added_by_club') }}</span>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateRangeFormat } from "@/inertia/composables/global";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => { } },
});

const tableFields = ["customer", "date", "added_by", "applied_at", "cancelled_at"];
</script>
