<template>
  <div>
    <In-Head :title="$t('lockers.index.title')" />

    <sub-header :title="$t('lockers.index.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          v-if="$page.props.permissions.canCreate"
          :href="$page.props.routes.create"
          old-link
          preset="add" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`lockers.index.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'name'">
                {{ slotProps.data?.name }}
                <span
                  v-if="slotProps.data?.trashed"
                  class="text-bo-red">({{ $t('generic.deleted2') }})</span>
              </template>
              <template v-else-if="col === 'locker_open'">
                <div class="flex items-center space-x-2">
                  <div
                    v-if="slotProps.data?.locker_open"
                    class="flex items-center sm-badge sm-badge-success">
                    <vue-feather
                      type="unlock"
                      class="mr-1"
                      size="12" /> <span>{{ $t('lockers.index.open') }}</span>
                  </div>
                  <div
                    v-else
                    class="flex items-center sm-badge sm-badge-warning">
                    <vue-feather
                      type="lock"
                      class="mr-1"
                      size="12" /> <span>{{ $t('lockers.index.close') }}</span>
                  </div>
                  <div v-if="slotProps.data?.activeAssignment">
                    <Link
                      :href="slotProps.data?.routes.showCustomer"
                      class="sm-badge sm-badge-info">
                      {{ slotProps.data?.activeAssignment.customer.name }}
                    </Link>
                  </div>
                </div>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                class="flex justify-end space-x-1">
                <sm-form-button
                  v-if="slotProps.data?.permissions.canUpdate"
                  v-tippy="slotProps.data?.locker_open ? $t('lockers.index.closing') : $t('lockers.index.opening')"
                  :icon="slotProps.data?.locker_open ? 'lock' : 'unlock'"
                  :route="slotProps.data?.routes.changeLocker"
                  :name="`changeLocker_${slotProps.data?.id}`"
                  method="post"
                  :confirm="$t('lockers.index.change_locker_alert')"
                />
                <sm-button
                  v-tippy="$t('generic.history')"
                  icon="list"
                  size="icon"
                  :href="slotProps.data?.routes.assignments" />
                <template v-if="!slotProps.data?.trashed">
                  <sm-button
                    v-if="slotProps.data?.permissions.canUpdate"
                    v-tippy="$t('generic.edit')"
                    preset="edit-icon"
                    old-link
                    :href="slotProps.data?.routes.edit" />
                  <sm-delete-button
                    v-if="slotProps.data?.permissions.canDelete"
                    :route="slotProps.data?.routes.delete"
                    name="deleteLocker"
                  />
                </template>

                <template v-else>
                  <sm-form-button
                    v-if="slotProps.data?.permissions.canRestore"
                    icon="rotate-ccw"
                    :route="slotProps.data?.routes.restore"
                    name="restoreLocker"
                    method="post"
                    confirm
                  />
                </template>
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { Link } from "@inertiajs/vue3";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref([
  "name", "locker_open",
]);

</script>
