<template>
  <sm-input-wrapper>
    <template
      v-if="$slots.afterLabel"
      #afterLabel>
      <slot name="afterLabel" />
    </template>
    <template
      v-if="$slots.prepend"
      #prepend>
      <slot name="prepend" />
    </template>
    <template
      v-if="$slots.afterInput"
      #afterInput>
      <slot name="afterInput" />
    </template>
    <template
      v-if="$slots.append"
      #append>
      <slot name="append" />
    </template>
    <div class="relative flex-1">
      <vc-date-picker
        :id="id"
        ref="datePickerRef"
        v-model.range="dateModelValue"
        name="dateRangePicker"
        locale="hu"
        :min-date="minDate"
        :clearable="clearable"
        :max-date="maxDate"
        :masks="{ data: 'YYYY. MM. DD.', input: featured ? 'MMM DD.' : 'YYYY. MM. DD.'}"
        :mode="mode"
        :required="required"
        :popover="popover"
        :input-debounce="300"
        @popover-did-show="popoverDidShow"
        @update:modelValue="updateInput($event)"
      >
        <template #default="{ inputValue, hidePopover, togglePopover }">
          <input
            :id="inputId"
            :key="datePickerKey"
            ref="datePickerInputRef"
            type="text"
            class="sm-input-text text-ellipsis"
            :title="inputValue.start && inputValue.end ? `${inputValue.start} - ${inputValue.end}` : ''"
            :class="[`sm-input-text${inputStyle ? '-'+inputStyle : ''}`, inputClasses, {'clearable': clearable}]"
            :value="inputValue.start && inputValue.end ? `${inputValue.start} - ${inputValue.end}` : ''"
            :required="required"
            :disabled="disabled"
            :placeholder="placeholder"
            @focus="togglePopover"
            @keypress.enter.prevent="onEnter(hidePopover)"
          >
        </template>
      </vc-date-picker>
      <button
        v-if="clearable && dateModelValue?.start"
        type="button"
        class="absolute px-2 mt-0.5 -translate-y-1/2 bg-white rounded right-px top-1/2"
        @click="clearPicker">
        <vue-feather
          type="x"
          size="13" />
      </button>
    </div>
    <template #error>
      <div v-auto-animate>
        <div
          v-if="localError !== ''"
          class="sm-feedback error">
          {{ localError }}
        </div>
      </div>
    </template>
  </sm-input-wrapper>
</template>

<script setup>
import SmInputWrapper from "@/inertia/components/forms/_layouts/SmInputWrapper.vue";
import { globalInputProps, useDateFormat } from "@/inertia/composables/global";
import { addDays, formatISO } from "date-fns";

const popover = ref({
  visibility: "focus",
});

const props = defineProps({
  ...globalInputProps,
  inputId: { type: String, required: false, default: "" },
  placeholder: { type: String, required: false, default: `pl: ${useDateFormat(new Date())} - ${useDateFormat(addDays(new Date(), 5))}` },
  modelValue: { type: Object, required: false, default: null },
  tooltip: { type: String, required: false, default: "" },
  focusToday: { type: Boolean, required: false, default: false },
  clearable: { type: Boolean, required: false, default: false },
  inputStyle: { type: String, required: false, default: null }, // pale, bg
  minDate: { type: [Date, String], required: false, default: null },
  maxDate: { type: [Date, String], required: false, default: null },
  returnFormatter: { type: String, required: false, default: "iso" },
  mode: { type: String, required: false, default: "dateRange" },
  preset: { type: [String, Object], required: false, default: null },
  featured: { type: Boolean, required: false, default: false },
});

const datePickerKey = ref(0);
const datePickerRef = ref();
const localError = ref("");

provide("props", props);

const formatter = (e) => {
  if (props.returnFormatter === "form" && e) {
    return useDateFormat(e, "form");
  } else if (props.returnFormatter === "iso" && e) {
    return formatISO(new Date(e));
  } else {
    return null;
  }
};

const dateModelValue = ref(props.modelValue.start && props.modelValue.end ? {
  start: formatter(props.modelValue.start ? props.modelValue.start : new Date()),
  end: formatter(props.modelValue.end ? props.modelValue.end : addDays(new Date(), 5))
} : "");

watch(() => props.modelValue, (e) => {
  dateModelValue.value = e && e !== "" ? { start: formatter(e?.start), end: formatter(e?.end) } : "";
});

const emit = defineEmits(["update:modelValue", "change", "onEnter", "clear"]);

function updateInput(e) {
  const formattedDate = { start: formatter(e?.start), end: formatter(e?.end) };
  emit("update:modelValue", formattedDate);
  emit("change", formattedDate);
}


const clearPicker = () => {
  dateModelValue.value = null;
  emit("clear", null);
};

function popoverDidShow() {
  if (props.focusToday) {
    datePickerRef.value.calendarRef.focusDate(new Date());
  }
}
</script>

<style>
.vc-popover-content-wrapper {
  z-index: 11;
}
</style>
