<template>
  <sm-card
    :header-title="$t('transactions.show.online.title')">
    <table class="sm-table sm-table-no-heading">
      <tbody>
        <tr>
          <td class="w-1/2">
            <strong>{{ $t('transactions.show.online.table.external_payment_id', {payment_service: paymentService}) }}</strong>
          </td>
          <td class="w-1/2">
            {{ transaction.external_payment_id }}
          </td>
        </tr>
        <tr>
          <td class="w-1/2">
            <strong>{{ $t('transactions.show.online.table.payment_started_at') }}</strong>
          </td>
          <td class="w-1/2">
            {{ transaction.payment_started_at }}
          </td>
        </tr>
        <template v-if="transaction.payment_service === 'simplepay'">
          <tr>
            <td class="w-1/2">
              <strong>{{ $t('transactions.show.online.table.interface', {payment_service: paymentService}) }}</strong>
            </td>
            <td class="w-1/2">
              V2
            </td>
          </tr>
          <tr>
            <td class="w-1/2">
              <strong>{{ $t('transactions.show.online.table.merchant_id', {payment_service: paymentService}) }}</strong>
            </td>
            <td class="w-1/2">
              {{ transaction.merchant_id }}
            </td>
          </tr>
        </template>
        <tr>
          <td class="w-1/2">
            <strong>{{ $t('transactions.show.online.table.external_payment_status', {payment_service: paymentService}) }}</strong>
          </td>
          <td class="w-1/2">
            {{ transaction.external_payment_status }}
          </td>
        </tr>
      </tbody>
    </table>
  </sm-card>
</template>

<script setup>
import { usePage } from "@inertiajs/vue3";

const page = usePage();
const transaction = ref(page.props.transaction);

const paymentService = computed(() => {
  if (page.props.transaction.payment_service === "simplepay") {
    return "SimplePay";
  } else if (page.props.transaction.payment_service === "vivavallet") {
    return "VivaWallet";
  } else if (page.props.transaction.payment_service === "barion") {
    return "Barion";
  }
});
</script>
