<script>
import AbstractCrudFilterType from "../utility/AbstractCrudFilterType";

export default {
  mixins: [AbstractCrudFilterType],
  data() {
    return {
      defaults: {
        type: this.data.filter.options.defaultOperator,
      },

      availableTypes: ["like", "not like", "=", "<>"],
      defaultIcon: "calendar",
    };
  },
  computed: {
    filterData() {
      return {
        term: this.data.data.term,
        type: this.data.data.type,
      };
    },
    displayableValue() {
      const operator = `crud_filter.${this.data.data.type}`;
      return `${window.trans(operator)} "${this.data.data.term}"`;
    }
  },
  render() {
    return this.data.filter.options.featured && this.fixed ? this.createDiv("sm-input-group !mb-0", [
      this.icon ? this.createDiv("sm-input-group-prepend", [
        this.createIcon(this.icon)
      ]) : null,
      this.createInput([this.data.data, "term"]),
    ]) : this.renderAbstract([
      this.createDiv("sm-col w-full lg:w-3/12", [
        this.createSelect([this.data.data, "type"], this.availableTypes.map(t => ({
          label: "crud_filter." + t,
          value: t
        }))),
      ]),
      this.createDiv("sm-col w-full lg:w-5/12", [
        this.createDiv("sm-input-group", [
          this.icon ? this.createDiv("sm-input-group-prepend", [
            this.createIcon(this.icon)
          ]) : null,
          this.createInput([this.data.data, "term"]),
        ]),
      ]),
    ]);
  },
};
</script>
