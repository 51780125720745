<template>
  <sm-button
    icon="edit-2"
    size="icon"
    @click="toggleModalVisibility" />
  <sm-modal
    :modal-is-visible="modal"
    :modal-title="$t('transactions.show.note_modal.title')"
    @toggleModalVisibility="toggleModalVisibility">
    <sm-input
      v-model="form.note"
      type="textarea"
      :placeholder="$t('transactions.show.note_modal.form.note.placeholder')" />
    <template #footer>
      <sm-button
        :title="$t('generic.cancel')"
        @click="toggleModalVisibility" />
      <sm-button
        color="primary"
        :title="$t('generic.save')"
        :loading="form.processing"
        @click="onSubmit" />
    </template>
  </sm-modal>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";

const props = defineProps({
  note: { type: String, required: true, default: "" }
});

const page = usePage();

const modal = ref(false);
const toggleModalVisibility = () => {
  modal.value = !modal.value;
};

const form = useForm({
  note: props.note !== "-" ? props.note : "",
});

provide("form", form);

const onSubmit = () => {
  form
    .patch(page.props.routes.updateNote, {
      only: ["transaction", "errors", "flash"],
      preserveState: true,
      onSuccess: () => {
        toggleModalVisibility();
      }
    });
};
</script>
