<template>
  <div>
    <In-Head :title="$t('sales.stock.show.product_units.title')" />

    <sub-header :title="$t('sales.stock.show.product_units.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          v-if="$page.props.permissions.canCreate"
          :href="$page.props.routes.create"
          old-link
          preset="add" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-table
        :table-data="$page.props.units"
        table-heading-key="sales.stock.show.product_units.table"
        :row-classes="{'sm-table-danger': 'isScrapWarehouse'}"
        :table-columns="tableColumns">
        >
        <template #label="slotProps">
          {{ slotProps.data?.label }}
          <span
            v-if="slotProps.data?.trashed"
            class="ml-1 text-bo-red">
            ({{ $t('generic.deleted') }})
          </span>
        </template>
        <template #actions="slotProps">
          <div
            v-if="!slotProps.data?.trashed"
            class="flex items-center justify-end space-x-1">
            <sm-button
              v-if="slotProps.data?.permissions.canUpdate"
              v-tippy="$t('generic.buttons.edit')"
              icon="edit"
              size="icon"
              old-link
              :href="slotProps.data?.routes.edit" />
            <sm-delete-button
              v-if="slotProps.data?.permissions.canDelete"
              :route="slotProps.data?.routes.destroy"
              :name="`deleteProductUnit_${slotProps.data?.id}`"
            />
          </div>
        </template>
      </sm-table>
    </sm-card>
  </div>
</template>

<script setup>
const tableColumns = ref(["label", "price", "amount"]);
</script>
