<script>
import { useDateFormat } from "@/inertia/composables/global";
import { addDays, addWeeks, endOfISOWeek, endOfMonth, formatISO, startOfISOWeek, startOfMonth } from "date-fns";
import AbstractCrudFilterType from "../utility/AbstractCrudFilterType";

export default {
  mixins: [AbstractCrudFilterType],
  props: {
    fixed: { type: Boolean, required: false, default: false }
  },
  data() {
    return {
      defaults: {
        operator: "=",
        term: {
          start: null,
          end: null
        },
      },

      presetModel: {
        term: null,
      },

      ranges: [
        {
          value: "unique",
          label: "filters.date_range.unique"
        },
        {
          value: "this_week",
          label: "filters.date_range.this_week"
        },
        {
          value: "past_week",
          label: "filters.date_range.past_week"
        },
        {
          value: "past_30_days",
          label: "filters.date_range.past_30_days"
        },
        {
          value: "this_month",
          label: "filters.date_range.this_month"
        }
      ],

      defaultIcon: "calendar",
    };
  },
  computed: {
    filterData() {
      return {
        term: {
          start: useDateFormat(this.data.data.term.start, "form"),
          end: useDateFormat(this.data.data.term.end, "form")
        },
      };
    },
    displayableValue() {
      const label = this.data.data.term ? `${useDateFormat(this.data.data.term.start)} - ${useDateFormat(this.data.data.term.end)}` : null;

      return `: ${label}`;
    }
  },
  watch: {
    presetModel: {
      handler: function (val) {
        if (val.term === "this_week") {
          this.data.data.term = {
            start: formatISO(startOfISOWeek(new Date())),
            end: formatISO(endOfISOWeek(new Date()))
          };
        } else if (val.term === "past_week") {
          this.data.data.term = {
            start: formatISO(startOfISOWeek(addDays(new Date(), -7))),
            end: formatISO(endOfISOWeek(addWeeks(new Date(), -1)))
          };
        } else if (val.term === "past_30_days") {
          this.data.data.term = {
            start: formatISO(addDays(new Date(), -30)),
            end: formatISO((new Date()))
          };
        } else if (val.term === "this_month") {
          this.data.data.term = {
            start: formatISO(startOfMonth(new Date())),
            end: formatISO(endOfMonth(new Date()))
          };
        } else if (val.term === "unique") {
          this.data.data.term = {
            start: null,
            end: null
          };
        }
      },
      deep: true
    },
  },
  // },
  render() {
    return this.data.filter.options.featured ?
      this.createDiv("flex items-center w-auto", [
        this.createDiv("mr-2", [
          this.createLabel(this.$t(this.data.filter.label || this.data.filter.field), {default: true}),
        ]),
        this.createDiv(`sm-input-group !mb-0 ${this.data.filter.options.featured ? "small" : ""}`, [
          this.createDiv("sm-input-group-prepend", [
            this.createIcon(this.icon)
          ]),
          this.createDiv("sm-input-group-extra", [
            this.createSelect([this.presetModel, "term"], this.ranges, "rangeSelect", { class: "sm-select-sm w-auto" }),
          ]),
          this.createDateRangePicker([this.data.data, "term"], "text", "input", {featured: true}),
        ])
      ]) : this.renderAbstract([
        this.createDiv("sm-col w-9/12", [
          this.createDiv("sm-row", [
            this.createDiv("sm-col w-[165px]", [
              this.createSelect([this.presetModel, "term"], this.ranges, "rangeSelect"),
            ]),
            this.createDiv("sm-col flex-1", [
              this.createDiv(`sm-input-group ${this.data.filter.options.featured ? "small" : ""}`, [
                this.createDiv("sm-input-group-prepend", [
                  this.createIcon(this.icon)
                ]),
                this.createDateRangePicker([this.data.data, "term"], "text", "input"),
              ]),
            ]),
          ]),
        ]),
      ]);
  },
};
</script>
