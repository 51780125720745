<template>
  <sm-toggle-button
    v-if="!slotProps.data.trashed"
    :title="$t('customers.tickets.table.actions.button')"
    align="right"
    :action-data="slotProps.data"
    :options="toggleButtonOptions" />

  <Teleport to="#action-modals">
    <sm-confirm-dialog :group="`invalidate_${uid}`" />
    <sm-confirm-dialog
      has-fake-accept-button
      :group="`use_action_${uid}`"
      :fake-accept-label="$t('generic.save')"
      @onFakeAccept="onFakeAccept">
      <sm-input
        v-model="useTicketForm.usage_count"
        class="mt-4"
        type="number"
        min="1"
        :label="$t('customers.tickets.actions.modals.use_action.label')"
        :error="useTicketForm?.errors?.usage_count"
        name="usage_count"
      />
    </sm-confirm-dialog>
    <season-ticket-change-validity-start-modal
      :modal-visibility="showSeasonTicketValidtyStartModal"
      :modal-data="seasonTicketValidtyStartModalData"
      @toggleModalVisibility="updateSeasonTicketValidtyStartModal"
    />
    <season-ticket-change-validity-end-modal
      :modal-visibility="showSeasonTicketValidtyEndModal"
      :modal-data="seasonTicketValidtyEndModalData"
      @toggleModalVisibility="updateSeasonTicketValidtyEndModal"
    />
    <season-ticket-change-ticket-amount-modal
      :modal-visibility="showChangeTicketAmountModal"
      :modal-data="changeTicketAmountModalData"
      @toggleModalVisibility="changeTicketAmountModal"
    />
    <season-ticket-delete-modal
      :modal-visibility="showDeleteTicketModal"
      :modal-data="deleteTicketModalData"
      @toggleModalVisibility="updateDeleteTicketModal"
    />
    <ticket-move-modal
      :modal-visibility="showMoveModal"
      :modal-data="moveModalData"
      @toggleModalVisibility="updateMoveModal"
    />
    <ticket-voucher-modal
      :modal-visibility="showVoucherModal"
      :modal-data="voucherModalData"
      @toggleModalVisibility="updateGenerateVoucherModal"
    />
  </Teleport>
</template>

<script setup>
import { useForm } from "@inertiajs/vue3";
import uniqueId from "lodash.uniqueid";
import { useConfirm } from "primevue/useconfirm";

import SeasonTicketChangeTicketAmountModal from "@/inertia/pages/customers/show/tickets/_modals/SeasonTicketChangeTicketAmountModal.vue";
import SeasonTicketChangeValidityEndModal from "@/inertia/pages/customers/show/tickets/_modals/SeasonTicketChangeValidityEndModal.vue";
import SeasonTicketChangeValidityStartModal from "@/inertia/pages/customers/show/tickets/_modals/SeasonTicketChangeValidityStartModal.vue";
import SeasonTicketDeleteModal from "@/inertia/pages/customers/show/tickets/_modals/SeasonTicketDeleteModal.vue";
import TicketMoveModal from "@/inertia/pages/customers/show/tickets/_modals/TicketMoveModal.vue";
import TicketVoucherModal from "@/inertia/pages/customers/show/tickets/_modals/TicketVoucherModal.vue";

defineProps({
  slotProps: { type: Object, required: true, default: null }
});

const confirm = useConfirm();
const uid = uniqueId();

// action: invalidate ticket
const invalidateTicket = (ticket) => {
  const form = useForm({});

  confirm.require({
    group: `invalidate_${uid}`,
    header: window.trans("customers.tickets.actions.modals.invalidate.header"),
    message: window.trans("generic.are_you_sure"),
    accept: () => {
      form
        .post(ticket.routes.invalidateTicket, {
          only: ["table", "customer", "errors", "flash"],
        });
    }
  });
};

// action: season ticket extension
const showSeasonTicketValidtyStartModal = ref(false);
const seasonTicketValidtyStartModalData = ref(null);

const updateSeasonTicketValidtyStartModal = (data) => {
  showSeasonTicketValidtyStartModal.value = !showSeasonTicketValidtyStartModal.value;
  seasonTicketValidtyStartModalData.value = data;
};

// action: season ticket extension
const showSeasonTicketValidtyEndModal = ref(false);
const seasonTicketValidtyEndModalData = ref(null);

const updateSeasonTicketValidtyEndModal = (data) => {
  showSeasonTicketValidtyEndModal.value = !showSeasonTicketValidtyEndModal.value;
  seasonTicketValidtyEndModalData.value = data;
};

// action: useTicket
const useTicketForm = useForm({
  usage_count: 1
});

const activeTicket = ref(null);

const useTicket = (ticket) => {
  confirm.require({
    group: `use_action_${uid}`,
    header: window.trans("customers.tickets.actions.modals.use_action.header"),
    onShow : () => {
      activeTicket.value = ticket;
    },
    onHide : () => {
      activeTicket.value = null;
    },
  });
};

const onFakeAccept = () => {
  useTicketForm
    .post(activeTicket.value.routes.useTicket, {
      only: ["table", "customer", "errors", "flash"],
      onSuccess: () => {
        useTicketForm.reset("usage_count");
        confirm.close();
      }
    });
};

// action: useTicketAmount
const showChangeTicketAmountModal = ref(false);
const changeTicketAmountModalData = ref(null);

const changeTicketAmountModal = (data) => {
  showChangeTicketAmountModal.value = !showChangeTicketAmountModal.value;
  changeTicketAmountModalData.value = data;
};

// action: deleteTicketModal
const showDeleteTicketModal = ref(false);
const deleteTicketModalData = ref(null);

const updateDeleteTicketModal = (data) => {
  showDeleteTicketModal.value = !showDeleteTicketModal.value;
  deleteTicketModalData.value = data;
};

// action: move ticket
const showMoveModal = ref(false);
const moveModalData = ref(null);

const updateMoveModal = (data) => {
  showMoveModal.value = !showMoveModal.value;
  moveModalData.value = data;
};

// action: voucher
const showVoucherModal = ref(false);
const voucherModalData = ref(null);

const updateGenerateVoucherModal = (data) => {
  showVoucherModal.value = !showVoucherModal.value;
  voucherModalData.value = data;
};

// toggle button helper
const toggleButtonOptions = ref([
  {
    id: "cancel",
    label: window.trans("customers.tickets.actions.cancel"),
    action: invalidateTicket,
    icon: "x-octagon",
    visible: ticket => ticket.permissions.canInvalidate && ticket.canBeInvalidated,
  },{
    id: "updateStart",
    label: window.trans("customers.tickets.actions.update_start_date"),
    action: updateSeasonTicketValidtyStartModal,
    icon: "sunrise",
    visible: ticket => ticket.permissions.canChangeValidityStart,
  },{
    id: "updateEnd",
    label: window.trans("customers.tickets.actions.update_end_date"),
    action: updateSeasonTicketValidtyEndModal,
    icon: "sunset",
    visible: ticket => ticket.permissions.canExtend && ticket.canExtendValidity,
  },{
    id: "endDateRequestes",
    label: window.trans("customers.tickets.actions.end_date_requests"),
    to: "viewExtensionHistory",
    icon: "clock",
    visible: ticket => ticket.permissions.canExtend && !ticket.trashed,
  },{
    id: "useTicket",
    label: window.trans("customers.tickets.actions.use"),
    action: useTicket,
    icon: "hash",
    visible: ticket => ticket.permissions.canUse && ticket.usable,
  },{
    id: "changeTicketAmount",
    label: window.trans("customers.tickets.actions.use_amount"),
    action: changeTicketAmountModal,
    icon: "hash",
    visible: ticket => ticket.permissions.canExtendUsages,
  },{
    id: "installments",
    label: window.trans("customers.tickets.actions.installment_details"),
    to: "viewInstallmentPayment",
    icon: "layers",
    visible: ticket => ticket.permissions.canViewInstallmentPayment && ticket.boughtWithInstallments,
  },{
    id: "move",
    label: window.trans("customers.tickets.actions.move"),
    action: updateMoveModal,
    icon: "log-in",
    visible: ticket => ticket.permissions.canMove,
  },{
    id: "voucher",
    label: window.trans("customers.tickets.actions.generate_voucher"),
    action: updateGenerateVoucherModal,
    icon: "tag",
    visible: ticket => ticket.permissions.canGenerateVoucher,
  },{
    id: "delete",
    label: window.trans("customers.tickets.actions.delete"),
    action: updateDeleteTicketModal,
    class: "danger",
    icon: "trash-2",
    visible: ticket => ticket.permissions.canDelete && !ticket.isExpired && ticket.usable,
  }
]);
</script>
