<script>
import AbstractCrudFilterType from "../utility/AbstractCrudFilterType";

export default {
  mixins: [AbstractCrudFilterType],
  data() {
    return {
      defaults: {
        term: "",
      },
      selectedOption: null,
    };
  },
  computed: {
    filterData() {
      return {
        term: this.data.data.term,
      };
    },
    displayableValue() {
      if (this.selectedOption) {
        return `: ${this.selectedOption.label}`;
      }

      return "";
    }
  },
  render() {
    return this.renderAbstract([
      this.createDiv("sm-col w-8/12", [
        this.createDiv("sm-input-group", [
          this.icon ? this.createDiv("sm-input-group-prepend", [
            this.createIcon(this.icon)
          ]) : null,
          this.createSearchableSelect([this.data.data, "term"], "test", {
            route: this.data.filter.options.route,
            optionSelected: e => this.selectedOption = e,
          }),
        ]),
      ]),
    ]);
  },
};
</script>
