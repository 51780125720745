<template>
  <div>
    <In-Head :title="$t('reports.service_utilization.title')" />

    <sub-header
      :title="$t('reports.service_utilization.title')"
      :export-route="$page.props.routes.export"
    >
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <div class="flex flex-row space-x-4">
      <sm-card
        v-for="(data, index) in infoData"
        :key="index"
        class="flex-1">
        <info-card
          :data="data"
          :loading="!$page.props.table"
          xl />
      </sm-card>
    </div>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`reports.service_utilization.columns.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template
                v-if="col === 'service_date'">
                {{ useDateRangeFormat(slotProps.data?.service_date.from, slotProps.data?.service_date.to) }}<br>
                {{ slotProps.data?.service_date.location }}
              </template>
              <template
                v-else-if="col === 'participants_count'">
                <span class="inline-flex items-center space-x-1">
                  <span>{{ slotProps.data?.attendees }}</span>
                  <span v-if="slotProps.data?.capacity > 0">/ {{ slotProps.data?.capacity }}</span>
                  <span>{{ $t('generic.member_count') }}</span>
                </span>
                <small v-if="slotProps.data?.capacity > 0">
                  ({{ Math.round((slotProps.data?.attendees / slotProps.data?.capacity) * 100) }}%)
                </small>
              </template>
              <template
                v-else-if="col === 'actual_participants_count'">
                <span class="inline-flex items-center space-x-1">
                  {{ slotProps.data?.actual_participants_count }} {{ $t('generic.member_count') }}
                </span>
                <small v-if="slotProps.data?.attendees > 0">
                  ({{ Math.round((slotProps.data?.actual_participants_count / slotProps.data?.attendees) * 100) }}%)
                </small>
              </template>
              <template
                v-else-if="col === 'female_participants'">
                <span class="inline-flex items-center space-x-1">
                  {{ slotProps.data?.female_participants }} {{ $t('generic.member_count') }}
                </span>
                <small v-if="slotProps.data?.attendees > 0">
                  ({{ Math.round((slotProps.data?.female_participants / slotProps.data?.attendees) * 100) }}%)
                </small>
              </template>
              <template
                v-else-if="col === 'male_participants'">
                <span class="inline-flex items-center space-x-1">
                  {{ slotProps.data?.male_participants }} {{ $t('generic.member_count') }}
                </span>
                <small v-if="slotProps.data?.attendees > 0">
                  ({{ Math.round((slotProps.data?.male_participants / slotProps.data?.attendees) * 100) }}%)
                </small>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateRangeFormat } from "@/inertia/composables/global";
import InfoCard from "@/inertia/pages/reports/_partials/InfoCard.vue";

const props = defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => { } },
});

const tableFields = ["service_name", "service_date", "coach", "participants_count", "actual_participants_count", "female_participants", "male_participants"];

const total = computed(() => props.table?.meta.total ?? "-");
const totalMemberCount = computed(() => `${props.table?.stats?.totalMemberCount ?? "-"} ${window.trans("generic.member_count")}`);
const returningMemberCount = computed(() => `${props.table?.stats?.returningMemberCount ?? "-"} ${window.trans("generic.member_count")}`);
const averageMemberCount = computed(() => `${props.table?.stats?.averageMemberCount ?? "-"} ${window.trans("generic.member_count")}`);

const infoData = ref([
  {
    color: "teal",
    icon: "calendar",
    name: window.trans("reports.service_utilization.cards.appointment_count"),
    data: total,
  },
  {
    color: "pink",
    icon: "users",
    name: window.trans("reports.service_utilization.cards.member_count"),
    data: totalMemberCount,
  },
  {
    color: "blue",
    icon: "repeat",
    name: window.trans("reports.service_utilization.cards.returning_member_count"),
    data: returningMemberCount,
  },
  {
    color: "green",
    icon: "bar-chart-2",
    name: window.trans("reports.service_utilization.cards.average_member_count"),
    data: averageMemberCount,
  }
]);
</script>
