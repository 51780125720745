<template>
  <div>
    <sm-button
      :disaled="data?.amount <= 0"
      icon="shuffle"
      size="icon"
      @click="toggleModalVisibility" />
    <sm-modal
      :modal-is-visible="modal"
      :modal-title="$t('sales.stock.show.item_registrations.title')"
      @toggleModalVisibility="toggleModalVisibility">
      <sm-select
        v-model="form.source_warehouse"
        name="source_warehouse"
        :label="$t('sales.stock.show.item_registrations.form.warehouse_from.label')"
        :options="warehouses"
        disabled
      />
      <sm-select
        v-model="form.destination_warehouse"
        name="destination_warehouse"
        :label="$t('sales.stock.show.item_registrations.form.warehouse_to.label')"
        :options="warehouses.filter(e => e?.id !== data.warehouse.id)"
      />
      <sm-input
        v-model="form.amount"
        name="amount"
        type="number"
        min="0"
        :max="data.amount"
        :label="$t('sales.stock.show.item_registrations.form.amount.label')"
        :help="`1 ${$t('sales.stock.show.item_registrations.amount')} = ${data.product.amount} ${data.product.abbreviation}`" />
      <sm-input
        v-model="form.note"
        name="note"
        type="textarea"
        :label="$t('sales.stock.show.item_registrations.form.note.label')" />
      <template #footer>
        <sm-button
          :title="$t('generic.cancel')"
          @click="toggleModalVisibility()" />
        <sm-button
          color="primary"
          :title="$t('generic.save')"
          :loading="form.processing"
          @click="onSubmit" />
      </template>
    </sm-modal>
  </div>
</template>

<script setup>
import { useFormatSelect } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";

const props = defineProps({
  data: { type: Object, required: true, default: null }
});

const modal = ref(false);
const toggleModalVisibility = () => {
  modal.value = !modal.value;
};

const page = usePage();

const warehouses = useFormatSelect(page.props.warehouses).filter(data => data.id !== String(props.data.warehouse.id));

const form = useForm({
  source_warehouse: props.data.warehouse,
  destination_warehouse: null,
  amount: null,
  note: null
});

provide("form", form);

const onSubmit = () => {
  form
    .transform(data => {
      console.log(data);
      return {
        ...data,
        source_warehouse: data.source_warehouse?.id,
        destination_warehouse: data.destination_warehouse?.id,
      };
    })
    .post(props.data.routes.move, {
      only: ["stocksByProduct", "flash", "errors"],
      onSuccess: () => {
        toggleModalVisibility();
        form.reset();
      }
    });
};
</script>
