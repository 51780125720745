<template>
  <div
    ref="smCrudFilter"
    class="inline-block smCrudFilter">
    <div
      class="inline-flex items-center space-x-2 sm-dropdown dropdown-icon"
      :class="{'show': visible}">
      <div
        v-if="fixedFilters.length !== 0"
        class="inline-flex items-center space-x-2">
        <template
          v-for="filter in fixedFilters"
          :key="filter.id">
          <component
            :is="filterComponents[filter.component]"
            :ref="`filter_${filter.id}`"
            :data="filter"
            fixed
            @remove="removeFilter($event, filter)"
            @clear="removeFilter($event, filter)" />
          <input
            type="hidden"
            name="filters[]"
            class="sm-input-text"
            :value="JSON.stringify({field: filter.filter.field, id: filter.id, term: filter.data.term})">
        </template>
        <sm-button
          icon="check"
          size="icon"
          color="white"
          @click="submit" />
      </div>
      <sm-button
        v-if="exportRoute"
        icon="download"
        title="Export"
        @click="exportData" />
      <sm-button
        v-if="fixedFilters.length !== availableFilters.length"
        icon="filter"
        color="filter"
        :title="$t('filters.filters')"
        @click="toggleControl"
      />
      <div
        ref="smCrudFilterRef"
        class="text-xs shadow-lg sm-dropdown-menu sm-dropdown-menu-right z-[100] rounded"
        :class="{'show': visible, 'mt-14': oneFilterIsActive}">
        <div
          style="width: 664px;"
          class="p-3">
          <p
            v-show="!showOnlyOneFilter"
            class="mb-2 text-sm font-semibold leading-5"
          >
            Szűrő hozzáadása
          </p>
          <div class="sm-row">
            <div
              v-show="!showOnlyOneFilter"
              class="w-full mb-4 sm-col"
            >
              <select
                v-model="newFilter"
                class="mb-4 sm-select"
                @change="addFilter">
                <option
                  value=""
                  disabled>
                  {{ $t('filters.choose_filter') }}
                </option>
                <template
                  v-for="filter in availableFilters"
                  :key="filter.type">
                  <option
                    v-if="!filter.options.featured"
                    :value="filter">
                    {{ filter.label || filter.field }}
                  </option>
                </template>
              </select>
              <div class="flex mb-4 text-center">
                <span class="block w-16 h-px mx-auto bg-bo-gray-100" />
              </div>
              <div class="text-center">
                <p class="text-sm">
                  Akár több szűrési feltételt is hozzáadhatsz a listából.
                </p>
              </div>
            </div>

            <div class="w-full sm-col crud-filter-list">
              <template
                v-for="filter in addedFilters"
                :key="filter.id">
                <component
                  :is="filterComponents[filter.component]"
                  v-if="!filter?.filter?.options?.featured"
                  v-show="!showOnlyOneFilter || controlFilter === filter.id"
                  :ref="`filter_${filter.id}`"
                  :data="filter"
                  class="mb-2"
                  @remove="removeFilter($event, filter)" />
              </template>
            </div>
          </div>

          <div class="flex justify-end mt-2 space-x-2 inline-center">
            <sm-button
              @click="reset">
              <span>{{ $t('filters.delete_filters') }}</span>
            </sm-button>

            <sm-button
              color="primary"
              @click="submit">
              <span>{{ $t('filters.apply_filters') }}</span>
            </sm-button>
          </div>
        </div>
      </div>
    </div>
    <div>
      <Teleport
        v-if="isMounted"
        to="#crud-filter-data">
        <div v-auto-animate>
          <div
            v-if="addedFilters.length > 0 && (String(fixedFilters.map(data => data.component)) !== String(addedFilters.map(data => data.component)))">
            <hr class="my-3">
            <div class="flex items-center justify-end mb-4 space-x-2">
              <template
                v-for="filter in addedFilters"
                :key="filter.id">
                <span
                  v-if="!filter.filter.options.featured"
                  class="inline-flex items-center leading-[0] sm-badge sm-badge-rect sm-badge-extra space-x-1.5 mb-0 cursor-pointer"
                  @click="toggleAndFilterControl(filter)"
                >
                  <span>
                    {{ filter.filter?.label }}
                    <template v-if="$refs[`filter_${filter.id}`]?.[0].displayableValue?.length">
                      {{ $refs[`filter_${filter.id}`]?.[0].displayableValue }}
                    </template>
                  </span>
                  <button
                    type="button"
                    @click="removeFilter($event, filter)">
                    <vue-feather
                      type="x"
                      size="16"
                      class="text-white" />
                  </button>
                </span>
              </template>
              <button
                type="button"
                class="flex items-center space-x-2.5 text-sm"
                @click="reset">
                <vue-feather
                  type="x"
                  size="16" />
                <span>{{ $t('filters.delete_filters') }}</span>
              </button>
            </div>
          </div>
        </div>
      </Teleport>
    </div>
  </div>
</template>

<script setup>
import { useForm, usePage } from "@inertiajs/vue3";
import { onClickOutside } from "@vueuse/core";
import { computed, onMounted, ref, shallowRef } from "vue";
import CrudBoolFilterType from "./filter_types/CrudBoolFilterType.vue";
import CrudDateFilterType from "./filter_types/CrudDateFilterType.vue";
import CrudDateRangeFilterType from "./filter_types/CrudDateRangeFilterType.vue";
import CrudNumberFilterType from "./filter_types/CrudNumberFilterType.vue";
import CrudScoutSearchFilterType from "./filter_types/CrudScoutSearchFilterType.vue";
import CrudSearchableFilterType from "./filter_types/CrudSearchableFilterType.vue";
import CrudSelectFilterType from "./filter_types/CrudSelectFilterType.vue";
import CrudTextFilterType from "./filter_types/CrudTextFilterType.vue";

const props = defineProps({
  availableFilters: {
    type: Array,
    default: () => [],
  },
  appliedFilters: {
    type: Array,
    default: () => [],
  },
  exportRoute: {
    type: String,
    default: ""
  },
});

const smCrudFilter = ref(null);

const form = useForm({});
const page = usePage();

const id = ref(1);
const oneFilterIsActive = ref(false);
const filterComponents = ref({
  CrudTextFilterType: shallowRef(CrudTextFilterType) ,
  CrudDateFilterType: shallowRef(CrudDateFilterType) ,
  CrudDateRangeFilterType: shallowRef(CrudDateRangeFilterType) ,
  CrudNumberFilterType: shallowRef(CrudNumberFilterType) ,
  CrudSelectFilterType: shallowRef(CrudSelectFilterType) ,
  CrudBoolFilterType: shallowRef(CrudBoolFilterType) ,
  CrudScoutSearchFilterType: shallowRef(CrudScoutSearchFilterType),
  CrudSearchableFilterType: shallowRef(CrudSearchableFilterType)
});

const addedFilters = ref([]);

const fixedFilters = ref([]);

const newFilter = ref("");
const visible = ref(false);
const isMounted = ref(false);

const controlFilter = ref(null);

const componentFromFilterType = (filterType) => {
  return "Crud" + filterType.component + "FilterType";
};

if (props.appliedFilters) {
  addedFilters.value = props.appliedFilters.map(fs => {
    const filter = JSON.parse(fs);
    if (!filter) {
      return null;
    }

    const filterType = props.availableFilters.find(af => af.field === filter.field);
    if (!filterType) {
      return null;
    }

    id.value = filter.id + 1;

    return {
      id: filter.id,
      component: componentFromFilterType(filterType),
      filter: filterType,
      data: filter,
    };
  }).filter(f => !!f);
}

const featuredFilters = props.availableFilters.filter(data => data.options?.featured).map(data => data.field);
const featuredIsInApplied = props.appliedFilters.find(data => featuredFilters.includes(JSON.parse(data).field));

const filterArray = props.appliedFilters.length !== 0 && featuredIsInApplied ? props.appliedFilters : props.availableFilters;

fixedFilters.value = filterArray.map(fs => {
  const filter = typeof fs === "object" ? fs : JSON.parse(fs);
  const filterType = props.availableFilters.find(af => af.field === filter.field);

  const isFeatured = filter?.options?.featured || filterType.options.featured;

  return isFeatured ? {
    id: filter.value,
    component: componentFromFilterType(filterType),
    filter: filterType,
    data: filter,
  } : null;
}).filter(f => !!f);

const showOnlyOneFilter = computed(() => {
  return controlFilter.value;
});

onMounted(() => {
  isMounted.value = true;
});

const addFilter = () => {
  if (!newFilter.value) {
    return;
  }

  addedFilters.value.push({
    id: id.value++,
    component: componentFromFilterType(newFilter.value),
    filter: newFilter.value,
    data: {},
  });

  newFilter.value = "";
};

const removeFilter = (event, filter) => {
  event?.stopPropagation();
  addedFilters.value = addedFilters.value.filter(f => f !== filter);
  setTimeout(() => {
    submit();
  }, 500);

  if (showOnlyOneFilter.value) {
    toggleControl();
  }
};

const reset = () => {
  addedFilters.value = [];
  // fixedFilters.value = [];
  resetFixedFilters();
  nextTick(() => {
    submit();
  });
};

const resetFixedFilters = () => {
  fixedFilters.value = fixedFilters.value.map(filter => {
    filter.data.term = null;
    return filter;
  });
};

const toggleControl = () => {
  visible.value = !visible.value;
  if (!visible.value) {
    oneFilterIsActive.value = false;
    controlFilter.value = null;
  }
};

const smCrudFilterRef = ref(null);
onClickOutside(smCrudFilterRef, () => {
  if (visible.value) {
    toggleControl();
  }
});

const toggleAndFilterControl = (control) => {
  oneFilterIsActive.value = true;
  controlFilter.value = control.id;
  toggleControl();
};

const submit = () => {
  let filters = Array.from(document.querySelector(".smCrudFilter").querySelectorAll("input[name='filters[]']"))
    .map(node => node.value);

  filters = filters.filter(data => {
    const d = JSON.parse(data);
    return d.term === null || d.term?.start === null ? null : data;
  });

  form
    .transform(() => {
      return {
        filters
      };
    })
    .get(page.url.split("?")[0], {
      only: ["table", "filter"],
      preserveState: true,
      preserveScroll: true,
      onSuccess: () => {
        oneFilterIsActive.value = false;
        visible.value = false;
      }
    });
};
const exportData = () => {
  const form = useForm({});

  let filters = Array.from(document.querySelector(".smCrudFilter").querySelectorAll("input[name='filters[]']"))
    .map(node => node.value);

  filters = filters.filter(data => {
    const d = JSON.parse(data);
    return d.term === null || d.term?.start === null ? null : data;
  });

  form
    .transform(() => {
      return {
        filters
      };
    })
    .post(props.exportRoute, {
      only: ["filter", "flash"]
    });
};
</script>
