<template>
  <div>
    <hr>
    <div class="flex flex-wrap space-x-1">
      <button
        v-for="(role, index) in tags?.roles"
        :key="index"
        class="sm-badge sm-badge-small sm-badge-primary"
        @click="toggleModal(role)">
        {{ $t(`roles.${role}`) }}
      </button>
    </div>

    <div class="flex flex-wrap space-x-1">
      <button
        v-for="(group, index) in tags?.groups"
        :key="index"
        class="sm-badge sm-badge-small sm-badge-primary"
        @click="toggleModal(group)">
        {{ group.name }}
      </button>
    </div>

    <div class="flex flex-wrap space-x-1">
      <button
        v-for="(contact, index) in tags?.contacts"
        :key="index"
        type="button"
        class="sm-badge sm-badge-small sm-badge-primary"
        @click="toggleModal(contact)">
        {{ contact.full_name }}
      </button>
    </div>
    <tag-member-modal
      :modal="modal"
      :user="user"
      @toggleModalVisibility="toggleModal" />
  </div>
</template>

<script setup>
import TagMemberModal from "./tags/TagMemberModal.vue";

defineProps({
  tags: { type: Object, required: false, default: null }
});

const modal = ref(false);
const user = ref(null);

const toggleModal = (data = null) => {
  user.value = data;
  modal.value = !modal.value;
};
</script>
