<template>
  <div>
    <In-Head :title="$t('products.product_type_groups.index.title')" />

    <sub-header :title="$t('products.product_type_groups.index.title')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          :href="$page.props.routes.create"
          old-link
          preset="add" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`products.product_type_groups.index.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'name'">
                <template v-if="slotProps.data?.trashed">
                  {{ slotProps.data?.name }}
                  <span class="text-bo-red"> ({{ $t('generic.deleted2') }})</span>
                </template>
                <template v-else>
                  <Link
                    :href="slotProps.data?.routes.show"
                    class="font-medium text-bo-blue">
                    {{ slotProps.data?.name }}
                  </Link>
                </template>
              </template>
              <template v-else-if="col === 'types_count'">
                {{ slotProps.data?.types_count }} db
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                v-if="!slotProps.data?.trashed"
                class="flex justify-end space-x-1">
                <sm-button
                  v-if="slotProps.data?.permissions?.canView"
                  v-tippy="$t('generic.show')"
                  preset="view-icon"
                  :href="slotProps.data?.routes.show" />
                <sm-button
                  v-if="slotProps.data?.permissions?.canUpdate"
                  v-tippy="$t('generic.edit')"
                  preset="edit-icon"
                  old-link
                  :href="slotProps.data?.routes.edit" />
                <sm-delete-button
                  v-if="slotProps.data?.permissions?.canDelete"
                  :route="slotProps.data?.routes.delete"
                  name="deleteProductTypeGroup"
                />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { Link } from "@inertiajs/vue3";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref([
  "name", "types_count"
]);
</script>
