<template>
  <div>
    <In-Head :title="$t('sales.inventory.transfer.title')" />

    <sub-header
      :title="$t('sales.inventory.transfer.title')"
      :export-route="$page.props.routes.export"
    >
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`sales.inventory.transfer.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'date'">
                {{ useDateFormat(slotProps.data?.date, 'long') }}
                <div
                  v-if="slotProps.data?.draft"
                  class="ml-1 sm-badge sm-badge-info">
                  {{ $t('sales.inventory.index.draft') }}
                </div>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <div
                class="flex justify-end space-x-1">
                <sm-button
                  v-if="slotProps.data?.transaction_id"
                  v-tippy="$t('sales.inventory.transfer.show_transaction')"
                  preset="view-icon"
                  old-link
                  :href="slotProps.data?.routes.show_transaction" />
                <sm-button
                  v-if="slotProps.data?.hasStockRegistration"
                  v-tippy="$t('sales.inventory.transfer.show_stock_registration')"
                  icon="log-in"
                  size="icon"
                  :href="slotProps.data?.routes.show_stock_registration" />
                <sm-button
                  v-if="slotProps.data?.hasInventoryScrap"
                  v-tippy="$t('sales.inventory.transfer.show_inventory_scrap')"
                  icon="log-in"
                  size="icon"
                  :href="slotProps.data?.routes.show_inventory_scrap" />
                <sm-button
                  v-if="slotProps.data?.note"
                  v-tippy="slotProps.data?.note"
                  icon="info"
                  size="icon"
                  color="info" />
              </div>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
});

const tableFields = ref(["name", "source", "destination", "amount", "weighted_average_price", "gross_weighted_average_price", "percentage", "net_value", "gross_value", "moved_by", "created_at"]);
</script>
