<template>
  <span>
    <sm-button
      v-tippy="$t('sales.installment_payments.installments.settle_modal.title')"
      icon="dollar-sign"
      size="icon"
      @click="toggleModalVisibility" />
    <sm-modal
      :modal-is-visible="modal"
      :modal-title="$t('sales.installment_payments.installments.settle_modal.title')"
      @toggleModalVisibility="toggleModalVisibility">
      <sm-select
        v-model="form.payment_type"
        name="payment_type"
        :label="$t('sales.installment_payments.installments.settle_modal.form.payment_type')"
        :options="paymentTypes"
      />
      <sm-select
        v-model="form.cash_register"
        name="cash_register"
        :label="$t('sales.installment_payments.installments.settle_modal.form.cash_register')"
        :options="cashRegisters"
      />
      <template #footer>
        <sm-button
          :title="$t('generic.cancel')"
          @click="toggleModalVisibility" />
        <sm-button
          color="primary"
          :title="$t('generic.save')"
          :loading="form.processing"
          @click="onSubmit" />
      </template>
    </sm-modal>
  </span>
</template>

<script setup>
import { useFormatSelect } from "@/inertia/composables/global";
import { useForm, usePage } from "@inertiajs/vue3";

const props = defineProps({
  slotProps: { type: Object, required: true, default: null }
});

const page = usePage();

const modal = ref(false);
const toggleModalVisibility = () => {
  modal.value = !modal.value;
};

const paymentTypes = useFormatSelect(page.props.settleModal.paymentTypes);
const cashRegisters = useFormatSelect(page.props.settleModal.cashRegisters);

const form = useForm({
  payment_type: null,
  cash_register: null,
});

const emits = defineEmits(["onSuccess"]);

provide("form", form);

const onSubmit = () => {
  form
    .transform((data) => ({
      ...data,
      payment_type: form.payment_type?.id,
      cash_register: form.cash_register?.id
    }))
    .post(props.slotProps.data.routes.settle, {
      only: ["installments", "errors", "flash"],
      onSuccess: () => {
        toggleModalVisibility();
        emits("onSuccess");
      }
    });
};
</script>
