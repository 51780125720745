<template>
  <div>
    <In-Head :title="$t('reports.active_tickets.title')" />

    <sub-header
      :title="$t('reports.active_tickets.title')"
      :export-route="$page.props.routes.export"
    >
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <div class="px-2 pt-2 pb-3 m-2 border-b border-bo-gray-200">
        <p><strong> {{ $t('reports.active_tickets.total') }}</strong>: <span class="ml-1">{{ totalTicketCount }}</span></p>
      </div>
      <sm-datatable
        loaded
        :sorts="sorts"
        :data="table">
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`reports.active_tickets.columns.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template
                v-if="col === 'customer'">
                <span v-if="typeof(slotProps.data?.customer) === 'string'">{{ slotProps.data?.customer }}</span>
                <sm-avatar
                  v-else
                  :user="slotProps.data?.customer" />
              </template>
              <template
                v-else-if="col === 'sold_at'">
                {{ useDateFormat(slotProps.data?.sold_at, 'long') }}
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <ticket-actions :slot-props="slotProps" />
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
    <div id="action-modals" />
  </div>
</template>

<script setup>
import { useDateFormat } from "@/inertia/composables/global";
import TicketActions from "@/inertia/pages/customers/show/tickets/_partials/TicketActions.vue";

const props = defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => { } },
  totalActiveTickets: { type: [String, Number], required: false, default: null },
});

const tableFields = ["customer", "ticket", "payment_type", "price", "created_at", "valid_from", "valid_to", "remaining_usage"];

const totalTicketCount = computed(() => {
  return props.table?.meta.total;
});
</script>
