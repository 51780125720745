<template>
  <div>
    <In-Head :title="$t('backoffice.pages.customers.index')" />

    <sub-header :title="$t('backoffice.pages.customers.index')">
      <template #breadcrumbs>
        <sm-breadcrumbs :data="$page.props.breadcrumbs" />
      </template>
      <template #actions>
        <sm-button
          v-if="canCreateCustomer"
          :title="$t('customers.table.new_profile')"
          href="/manage/customers/create"
          color="primary"
          icon="plus-circle" />
      </template>
    </sub-header>

    <sm-card no-padding>
      <sm-datatable
        :data="table"
        :sorts="sorts"
        paginator>
        <template #columns>
          <Column
            v-for="(col, index) of tableFields"
            :key="col + '_' + index"
            :field="col"
            :header="$t(`customers.table.${col}`)"
            :sortable="sorts.allowed.find(data => data === col)">
            <template #body="slotProps">
              <template v-if="col === 'name'">
                <sm-avatar
                  :user="slotProps.data" />
              </template>
              <template v-else-if="col === 'date_of_birth'">
                {{ useDateFormat(slotProps.data?.date_of_birth) }}
              </template>
              <template v-else-if="col === 'registered_at'">
                {{ useDateFormat(slotProps.data?.registered_at) }}
              </template>
              <template v-else-if="col === 'last_checkin'">
                {{ useDateFormat(slotProps.data?.last_checkin) }}
              </template>
              <template v-else-if="col === 'checked_in'">
                <span v-if="slotProps.data?.checked_in">
                  <vue-feather
                    type="check"
                    class="w-4 h-4 text-bo-green" />
                </span>
                <span v-else>
                  <vue-feather
                    type="x"
                    class="w-4 h-4 text-bo-red" />
                </span>
              </template>
              <template v-else-if="col === 'balance'">
                <span
                  class="p-1 mb-0 font-bold border-0 sm-alert whitespace-nowrap"
                  :class="slotProps.data?.balance < 0 ? 'sm-alert-danger' : slotProps.data?.balance > 0 ? 'sm-alert-success' : 'sm-alert-warning'">
                  {{ usePriceFormat(slotProps.data?.balance) }}
                </span>
              </template>
              <template v-else>
                {{ slotProps.data?.[col] }}
              </template>
            </template>
          </Column>
          <Column field="actions">
            <template #body="slotProps">
              <Link
                class="sm-btn sm-btn-xs"
                :href="slotProps.data?.profile_url"
                :title="$t('customers.table.profile')">
                {{ $t('customers.table.profile') }}
              </Link>
            </template>
          </Column>
        </template>
      </sm-datatable>
    </sm-card>
  </div>
</template>

<script setup>
import { useDateFormat, usePriceFormat } from "@/inertia/composables/global";
import { Link } from "@inertiajs/vue3";

defineProps({
  table: { type: Object, required: false, default: () => {} },
  sorts: { type: Object, required: false, default: () => {} },
  canCreateCustomer: { type: Boolean, required: false, default: true },
});

const tableFields = ref([
  "name",
  "checked_in",
  "email",
  "date_of_birth",
  "balance",
  "registered_at",
  "last_checkin"
]);
</script>
